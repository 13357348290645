// Filename: api/statsApi.js
import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Start GSC data fetch for a project
export const startGSCDataFetch = async (projectId) => {
  const response = await apiClient.post(
    `/projects/${projectId}/gsc/fetch-data/`
  );
  return response.data;
};

// Get GSC properties for a project
export const getGSCProperties = async (projectId) => {
  const response = await apiClient.get(
    `/projects/${projectId}/gsc/properties/`,
    {
      withCredentials: true,
    }
  );
  console.log("blabla: ", response.data);
  return response.data;
};

// Select GSC property for a project
export const selectGSCProperty = async (projectId, selectedProperty) => {
  const response = await apiClient.post(
    `/projects/${projectId}/gsc/select-property/`,
    {
      selected_property: selectedProperty,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
};
