// components/ExperimentFilter.js

import React, { useCallback } from "react";
import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

const ExperimentFilter = ({ onFilterChange }) => {
  const { t } = useTranslation("experiments");
  const [form] = Form.useForm();

  const handleValuesChange = useCallback(
    debounce((changedValues, allValues) => {
      onFilterChange(allValues);
    }, 500),
    [onFilterChange]
  );

  return (
    <Form
      form={form}
      layout="inline"
      onValuesChange={handleValuesChange}
      initialValues={{
        ordering: "-created_at",
      }}
    >
      {/* Search Input */}
      <Form.Item name="search">
        <Input.Search
          placeholder={t("experiment-filter.placeholder.search")}
          allowClear
          style={{ width: 200 }}
        />
      </Form.Item>
      {/* Experiment Type */}
      <Form.Item name="experiment_type">
        <Select
          placeholder={t("experiment-filter.filter.experiment_type")}
          style={{ width: 200 }}
          allowClear
        >
          <Select.Option value="single">
            {t("experiment-filter.options.single")}
          </Select.Option>
          <Select.Option value="group">
            {t("experiment-filter.options.group")}
          </Select.Option>
          <Select.Option value="split">
            {t("experiment-filter.options.split")}
          </Select.Option>
          <Select.Option value="switch">
            {t("experiment-filter.options.switch")}
          </Select.Option>
        </Select>
      </Form.Item>
      {/* Success Status */}
      <Form.Item name="successful">
        <Select
          placeholder={t("experiment-filter.filter.successful")}
          style={{ width: 200 }}
          allowClear
        >
          <Select.Option value="true">
            {t("experiment-filter.options.successful")}
          </Select.Option>
          <Select.Option value="false">
            {t("experiment-filter.options.unsuccessful")}
          </Select.Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export default ExperimentFilter;
