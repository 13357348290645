import React from "react";
import { Form, Input, Checkbox, Select, Divider } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ScrapingTab = ({ form, scrapingEnabled, setScrapingEnabled }) => {
  const { t } = useTranslation("projects");
  return (
    <>
      <Form.Item
        label={t("projects-scraping.label.sitemap_url")}
        name="sitemap_url"
      >
        <Input placeholder="https://www.domain.ext/sitemap.xml" />
      </Form.Item>
      <Form.Item name="scraping_enabled" valuePropName="checked">
        <Checkbox onChange={(e) => setScrapingEnabled(e.target.checked)}>
          {t("projects-scraping.label.enable_scraping")}
        </Checkbox>
      </Form.Item>
      {scrapingEnabled && (
        <>
          <div style={{ paddingLeft: "2em" }}>
            <Form.Item
              label={t("projects-scraping.label.scraping_mode")}
              name="scraping_mode"
              rules={[
                {
                  required: true,
                  message: t(
                    "projects-scraping.validation.scraping_mode_required"
                  ),
                },
              ]}
            >
              <Select>
                <Option value="sitemap">
                  {t("projects-scraping.label.sitemap")}
                </Option>
                <Option value="crawler">
                  {t("projects-scraping.label.crawler")}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item name="scraping_render_js" valuePropName="checked">
              <Checkbox>
                {t("projects-scraping.label.scraping_render_js")}
              </Checkbox>
            </Form.Item>
          </div>
          <Divider />
        </>
      )}
    </>
  );
};

export default ScrapingTab;
