import {
  fetchChangeTypes,
  createChangeType,
  updateChangeType,
  deleteChangeType,
} from "../api/changetypesApi";

// Fetch ChangeTypes
export const getChangeTypes = (projectId) => async (dispatch) => {
  dispatch({ type: "GET_CHANGE_TYPES_REQUEST" });
  try {
    const data = await fetchChangeTypes(projectId);
    dispatch({
      type: "GET_CHANGE_TYPES_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CHANGE_TYPES_FAILURE",
      error: error.message,
    });
  }
};

// Add ChangeType
export const addChangeTypeAction =
  (projectId, changeTypeData) => async (dispatch) => {
    dispatch({ type: "ADD_CHANGE_TYPE_REQUEST" });
    try {
      const data = await createChangeType(projectId, changeTypeData);
      dispatch({
        type: "ADD_CHANGE_TYPE_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "ADD_CHANGE_TYPE_FAILURE",
        error: error.message,
      });
    }
  };

// Edit ChangeType
export const editChangeTypeAction =
  (projectId, changeTypeId, changeTypeData) => async (dispatch) => {
    dispatch({ type: "EDIT_CHANGE_TYPE_REQUEST" });
    try {
      const data = await updateChangeType(
        projectId,
        changeTypeId,
        changeTypeData
      );
      dispatch({
        type: "EDIT_CHANGE_TYPE_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "EDIT_CHANGE_TYPE_FAILURE",
        error: error.message,
      });
    }
  };

// Remove ChangeType
export const removeChangeTypeAction =
  (projectId, changeTypeId) => async (dispatch) => {
    dispatch({ type: "REMOVE_CHANGE_TYPE_REQUEST" });
    try {
      await deleteChangeType(projectId, changeTypeId);
      dispatch({
        type: "REMOVE_CHANGE_TYPE_SUCCESS",
        payload: changeTypeId,
      });
    } catch (error) {
      dispatch({
        type: "REMOVE_CHANGE_TYPE_FAILURE",
        error: error.message,
      });
    }
  };
