import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Spin, Typography, message, Empty, Divider } from "antd";
import { getPages } from "../actions/pagesActions";
import PageFilter from "./PageFilter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PagesList = () => {
  const dispatch = useDispatch();
  const { pages, loading, error } = useSelector((state) => state.pages);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const { t } = useTranslation("pages"); // Gebruik specifieke namespace voor consistentie

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filters, setFilters] = useState({});
  const [ordering, setOrdering] = useState("-created_at");

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1); // Reset naar eerste pagina bij filterwijziging
  };

  useEffect(() => {
    if (currentProject?.id) {
      console.log("currentProject", currentProject);
      const params = {
        page,
        page_size: pageSize,
        ordering,
        ...filters,
        include: "stats",
      };
      dispatch(getPages(currentProject.id, params));
    }
  }, [currentProject, dispatch, page, pageSize, filters, ordering]);

  useEffect(() => {
    if (error) {
      message.error(t("pages-list.error.loading_failed"));
    }
  }, [error, t]);

  // Definieer de kolommen voor de tabel
  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: t("pages-list.label.title"),
        dataIndex: "title",
        key: "title",
        sorter: true,
        render: (text, record) => (
          <Link to={`/pages/${record.id}/`}>
            {text || t("pages-list.label.no_value")}
          </Link>
        ),
        width: 250,
      },
      {
        title: t("pages-list.label.url"),
        dataIndex: "url",
        key: "url",
        sorter: true,
        render: (text) => (
          <a href={text} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        ),
        width: 300,
      },
      {
        title: t("pages-list.label.created_at"),
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
        render: (text) => new Date(text).toLocaleString(),
        width: 180,
      },
      {
        title: t("pages-list.label.last_seen"),
        dataIndex: "last_seen",
        key: "last_seen",
        sorter: true,
        render: (text) => new Date(text).toLocaleString(),
        width: 180,
      },
    ];
    const statsColumns = [
      {
        title: t("pages-list.label.impressions"),
        dataIndex: "impressions",
        key: "impressions",
        sorter: true,
        render: (value) => value || 0,
        width: 150,
      },
      {
        title: t("pages-list.label.clicks"),
        dataIndex: "clicks",
        key: "clicks",
        sorter: true,
        render: (value) => value || 0,
        width: 150,
      },
      {
        title: t("pages-list.label.ctr"),
        dataIndex: "ctr",
        key: "ctr",
        sorter: true,
        render: (value) =>
          value !== undefined && value !== null
            ? `${(value * 100).toFixed(2)}%`
            : "0.00%",
        width: 150,
      },
      {
        title: t("pages-list.label.position"),
        dataIndex: "position",
        key: "position",
        sorter: true,
        render: (value) =>
          value !== undefined && value !== null ? value.toFixed(2) : "0.00",
        width: 150,
      },
    ];
    return [...baseColumns, ...statsColumns];
  }, [t, currentProject]);

  // Handmatige paginatie instellingen via Ant Design Table
  const handleTableChange = (pagination, filtersFromTable, sorter) => {
    // Update pagina en paginagrootte
    setPage(pagination.current);
    setPageSize(pagination.pageSize);

    // Update ordering op basis van sorteer informatie
    if (sorter.order) {
      const orderPrefix = sorter.order === "ascend" ? "" : "-";
      setOrdering(`${orderPrefix}${sorter.field}`);
    } else {
      setOrdering("-created_at"); // Standaard ordering
    }
  };

  return (
    <div>
      <Typography.Title level={2}>{t("pages-list.title")}</Typography.Title>
      <PageFilter onFilterChange={handleFilterChange} />
      <Divider />
      {/* Tabel met pagina's */}
      {pages.results && pages.results.length > 0 ? (
        <Table
          columns={columns}
          dataSource={pages.results}
          rowKey="id"
          loading={loading}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: pages.count,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} van ${total} items`,
          }}
          onChange={handleTableChange}
          locale={{
            emptyText: <Empty description={t("pages-list.no_pages")} />,
          }}
          bordered
        />
      ) : (
        <Empty description={t("pages-list.no_pages")} />
      )}
    </div>
  );
};

export default PagesList;
