import {
  fetchProjects,
  fetchProjectById,
  createProject,
  updateProject,
  deleteProject,
  fetchProjectUsers,
  createProjectUser,
  updateProjectUser,
  deleteProjectUser,
} from "../api/projectsApi";

import { startScrape } from "../api/scrapeApi";

import {
  startGSCDataFetch,
  getGSCProperties,
  selectGSCProperty,
} from "../api/statsApi";

// Fetch all projects
export const getProjects = (teamId) => async (dispatch) => {
  dispatch({ type: "PROJECTS_LOADING" });

  try {
    const data = await fetchProjects(teamId);
    dispatch({
      type: "PROJECTS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "PROJECTS_FAILURE",
      error: error.message,
    });
  }
};

// Fetch a single project
export const getProject = (projectId) => async (dispatch) => {
  dispatch({ type: "PROJECT_LOADING" });

  try {
    const data = await fetchProjectById(projectId);
    dispatch({
      type: "PROJECT_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "PROJECT_FAILURE",
      error: error.message,
    });
  }
};

// Set current project action
export const setCurrentProject = (project) => ({
  type: "SET_CURRENT_PROJECT",
  payload: project,
});

// Create a new project
export const createNewProject = (projectData) => async (dispatch) => {
  try {
    const data = await createProject(projectData);
    dispatch({
      type: "CREATE_PROJECT_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "CREATE_PROJECT_FAILURE",
      error: error.message,
    });
    throw error;
  }
};

// Update a project
export const updateExistingProject =
  (projectId, projectData) => async (dispatch) => {
    try {
      const data = await updateProject(projectId, projectData);
      dispatch({
        type: "UPDATE_PROJECT_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_PROJECT_FAILURE",
        error: error.message,
      });
      throw error;
    }
  };

// Delete a project
export const deleteExistingProject = (projectId) => async (dispatch) => {
  try {
    await deleteProject(projectId);
    dispatch({
      type: "DELETE_PROJECT_SUCCESS",
      payload: projectId,
    });
  } catch (error) {
    dispatch({
      type: "DELETE_PROJECT_FAILURE",
      error: error.message,
    });
    throw error;
  }
};

// Start scraping for a project
export const startProjectScrape = (projectId) => async (dispatch) => {
  dispatch({ type: "PROJECT_SCRAPE_START", payload: projectId });

  try {
    const data = await startScrape(projectId);
    dispatch({
      type: "PROJECT_SCRAPE_SUCCESS",
      payload: { projectId, taskId: data.task_id },
    });
    // Optionally, dispatch a notification or message here
  } catch (error) {
    dispatch({
      type: "PROJECT_SCRAPE_FAILURE",
      payload: { projectId, error: error.message },
    });
    throw error; // Re-throw the error to handle it in the component
  }
};

// Start GSC OAuth flow with redirect URI
export const startGSCOAuth = (projectId) => {
  window.location.href = `http://127.0.0.1:8000/api/projects/${projectId}/gsc/oauth-start/`;
};

// Fetch GSC properties after OAuth
export const fetchGSCProperties = (projectId) => async (dispatch) => {
  dispatch({ type: "GSC_PROPERTIES_LOADING" });
  try {
    const data = await getGSCProperties(projectId);
    dispatch({
      type: "GSC_PROPERTIES_SUCCESS",
      payload: data.sites,
    });
    return data;
  } catch (error) {
    dispatch({
      type: "GSC_PROPERTIES_FAILURE",
      error: error.message,
    });
    throw error;
  }
};

// Select GSC property
export const selectGSCPropertyAction =
  (projectId, selectedProperty) => async (dispatch) => {
    dispatch({ type: "GSC_SELECT_PROPERTY_LOADING" });
    try {
      const data = await selectGSCProperty(projectId, selectedProperty);
      dispatch({
        type: "GSC_SELECT_PROPERTY_SUCCESS",
        payload: data.message,
      });
      //message.success("Google Search Console linked successfully.");
    } catch (error) {
      dispatch({
        type: "GSC_SELECT_PROPERTY_FAILURE",
        error: error.message,
      });
      throw error;
      //message.error(`Failed to link Google Search Console: ${error.message}`);
    }
  };

// Start GSC data fetch for a project
export const startProjectDataFetch = (projectId) => async (dispatch) => {
  dispatch({ type: "PROJECT_DATA_FETCH_START", payload: projectId });

  try {
    const data = await startGSCDataFetch(projectId);
    dispatch({
      type: "PROJECT_DATA_FETCH_SUCCESS",
      payload: { projectId, taskId: data.task_id },
    });
    //message.success("GSC data fetch started.");
  } catch (error) {
    dispatch({
      type: "PROJECT_DATA_FETCH_FAILURE",
      payload: { projectId, error: error.message },
    });
    throw error;
    //message.error(`Failed to start GSC data fetch: ${error.message}`);
  }
};
