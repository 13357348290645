// components/KeywordPageFilter.js

import React, { useCallback } from "react";
import { Form, Input, Segmented, Typography } from "antd";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

const KeywordPageFilter = ({ onFilterChange }) => {
  const { t } = useTranslation("keywords");
  const [form] = Form.useForm();

  // Debounced filter change handler
  const debouncedFilterChange = useCallback(
    debounce((newFilters) => {
      onFilterChange(newFilters);
    }, 500),
    [onFilterChange]
  );

  const handleValuesChange = (changedValues, allValues) => {
    const { search, stats_date_range } = allValues;
    const newFilters = {};

    if (search) {
      newFilters.search = search;
    }

    newFilters.stats_date_range = stats_date_range || "last_month";
    debouncedFilterChange(newFilters);
  };

  return (
    <Form
      form={form}
      layout="inline"
      onValuesChange={handleValuesChange}
      initialValues={{
        stats_date_range: "last_month",
      }}
    >
      <Form.Item name="search">
        <Input.Search
          placeholder={t("keyword-detail.pages.filter.placeholder.search")}
          allowClear
          style={{ width: 200 }}
        />
      </Form.Item>
      <Form.Item
        name="stats_date_range"
        label={t("keyword-detail.pages.stats.label")}
      >
        <Segmented
          options={[
            {
              label: t("keyword-detail.pages.stats.last_week"),
              value: "last_week",
            },
            {
              label: t("keyword-detail.pages.stats.last_month"),
              value: "last_month",
            },
            {
              label: t("keyword-detail.pages.stats.last_year"),
              value: "last_year",
            },
          ]}
        />
      </Form.Item>
    </Form>
  );
};

export default KeywordPageFilter;
