// components/ExperimentsList.js

import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  Spin,
  Typography,
  message,
  Empty,
  Divider,
  Button,
  Popconfirm,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getExperiments,
  updateExperimentStatusAction,
  deleteExperimentAction,
} from "../actions/experimentsActions";
import ExperimentFilter from "./ExperimentFilter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";

// Import the new components
import ExperimentTypeSelector from "./ExperimentTypeSelector";
import SingleExperimentForm from "./experimentForms/SingleExperimentForm";
import GroupExperimentForm from "./experimentForms/GroupExperimentForm";
import SplitExperimentForm from "./experimentForms/SplitExperimentForm";
import SwitchExperimentForm from "./experimentForms/SwitchExperimentForm";

const ExperimentsList = () => {
  const dispatch = useDispatch();
  const { experiments, loading, error } = useSelector(
    (state) => state.experiments
  );
  const currentProject = useSelector((state) => state.projects.currentProject);
  const { t } = useTranslation("experiments");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filters, setFilters] = useState({});
  const [ordering, setOrdering] = useState("-created_at");

  // State for modals
  const [experimentTypeModalVisible, setExperimentTypeModalVisible] =
    useState(false);
  const [selectedExperimentType, setSelectedExperimentType] = useState(null);

  useEffect(() => {
    if (currentProject?.id) {
      const params = {
        page,
        page_size: pageSize,
        ordering,
        ...filters,
      };
      dispatch(getExperiments(currentProject.id, params));
    }
  }, [currentProject, dispatch, page, pageSize, filters, ordering]);

  useEffect(() => {
    if (error) {
      message.error(t("experiments-list.error.loading_failed"));
    }
  }, [error, t]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1); // Reset to first page when filters change
  };

  const handleTableChange = (pagination, filtersFromTable, sorter) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);

    if (sorter.order) {
      const orderPrefix = sorter.order === "ascend" ? "" : "-";
      setOrdering(`${orderPrefix}${sorter.field}`);
    } else {
      setOrdering("-created_at");
    }
  };

  const showExperimentTypeModal = () => {
    setExperimentTypeModalVisible(true);
  };

  const handleExperimentTypeSelect = (type) => {
    setSelectedExperimentType(type);
    setExperimentTypeModalVisible(false);
  };

  const handleCloseExperimentForm = () => {
    setSelectedExperimentType(null);
  };

  const handleUpdateStatus = (experimentId, status) => {
    dispatch(updateExperimentStatusAction(experimentId, status))
      .then(() => {
        message.success(t("experiments-list.success.status_updated"));
        // Refresh the experiments list
        dispatch(getExperiments(currentProject.id, {}));
      })
      .catch((err) => {
        message.error(t("experiments-list.error.status_update_failed"));
        console.error(err);
      });
  };

  const handleDeleteExperiment = (experimentId) => {
    dispatch(deleteExperimentAction(experimentId))
      .then(() => {
        message.success(t("experiments-list.success.experiment_deleted"));
        // Refresh the experiments list
        dispatch(getExperiments(currentProject.id, {}));
      })
      .catch((err) => {
        message.error(t("experiments-list.error.deletion_failed"));
        console.error(err);
      });
  };

  const columns = useMemo(() => {
    return [
      {
        title: t("experiments-list.label.experiment_name"),
        dataIndex: "experiment_name",
        key: "experiment_name",
        sorter: true,
        render: (text, record) => (
          <Link to={`/experiments/${record.id}/`}>
            {text || t("experiments-list.label.no_value")}
          </Link>
        ),
        width: 250,
      },
      {
        title: t("experiments-list.label.experiment_type"),
        dataIndex: "experiment_type",
        key: "experiment_type",
        sorter: true,
        width: 150,
      },
      {
        title: t("experiments-list.label.control_period"),
        dataIndex: "control_start_date",
        key: "control_period",
        render: (text, record) =>
          `${record.control_start_date} - ${record.control_end_date}`,
        width: 200,
      },
      {
        title: t("experiments-list.label.test_period"),
        dataIndex: "test_start_date",
        key: "test_period",
        render: (text, record) =>
          `${record.test_start_date} - ${record.test_end_date}`,
        width: 200,
      },
      {
        title: t("experiments-list.label.successful"),
        dataIndex: "successful",
        key: "successful",
        render: (value) =>
          value === true
            ? t("experiments-list.label.successful_true")
            : value === false
            ? t("experiments-list.label.successful_false")
            : t("experiments-list.label.successful_null"),
        width: 100,
      },
      {
        title: t("experiments-list.label.actions"),
        key: "actions",
        render: (text, record) => (
          <>
            <Button
              type="link"
              onClick={() => handleUpdateStatus(record.id, !record.successful)}
            >
              {record.successful
                ? t("experiments-list.action.mark_unsuccessful")
                : t("experiments-list.action.mark_successful")}
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title={t("experiments-list.confirm.delete")}
              onConfirm={() => handleDeleteExperiment(record.id)}
              okText={t("experiments-list.confirm.yes")}
              cancelText={t("experiments-list.confirm.no")}
            >
              <Button type="link" danger>
                {t("experiments-list.action.delete")}
              </Button>
            </Popconfirm>
          </>
        ),
        width: 200,
      },
    ];
  }, [t]);

  return (
    <div>
      <Typography.Title level={2}>
        {t("experiments-list.title")}
      </Typography.Title>
      <Button type="primary" onClick={showExperimentTypeModal}>
        {t("experiments-list.button.create_experiment")}
      </Button>
      <Divider />
      <ExperimentFilter onFilterChange={handleFilterChange} />
      <Divider />
      {experiments.results && experiments.results.length > 0 ? (
        <Table
          columns={columns}
          dataSource={experiments.results}
          rowKey="id"
          loading={loading}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: experiments.count,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t(
                "experiments-list.pagination.of"
              )} ${total} ${t("experiments-list.pagination.items")}`,
          }}
          onChange={handleTableChange}
          locale={{
            emptyText: (
              <Empty description={t("experiments-list.no_experiments")} />
            ),
          }}
          bordered
        />
      ) : (
        <Empty description={t("experiments-list.no_experiments")} />
      )}

      {/* Experiment Type Selection Modal */}
      <ExperimentTypeSelector
        visible={experimentTypeModalVisible}
        onSelect={handleExperimentTypeSelect}
        onCancel={() => setExperimentTypeModalVisible(false)}
      />

      {/* Render specific experiment form based on selected type */}
      {selectedExperimentType === "single" && (
        <SingleExperimentForm
          visible={true}
          onCancel={handleCloseExperimentForm}
          onSuccess={() => {
            handleCloseExperimentForm();
            dispatch(getExperiments(currentProject.id, {}));
          }}
        />
      )}
      {selectedExperimentType === "group" && (
        <GroupExperimentForm
          visible={true}
          onCancel={handleCloseExperimentForm}
          onSuccess={() => {
            handleCloseExperimentForm();
            dispatch(getExperiments(currentProject.id, {}));
          }}
        />
      )}
      {selectedExperimentType === "split" && (
        <SplitExperimentForm
          visible={true}
          onCancel={handleCloseExperimentForm}
          onSuccess={() => {
            handleCloseExperimentForm();
            dispatch(getExperiments(currentProject.id, {}));
          }}
        />
      )}
      {selectedExperimentType === "switch" && (
        <SwitchExperimentForm
          visible={true}
          onCancel={handleCloseExperimentForm}
          onSuccess={() => {
            handleCloseExperimentForm();
            dispatch(getExperiments(currentProject.id, {}));
          }}
        />
      )}
    </div>
  );
};

export default ExperimentsList;
