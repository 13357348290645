const initialState = {
  changelogs: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  loading: false,
  error: null,
};

export default function changelogsReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGELOGS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "CHANGELOGS_SUCCESS":
      return {
        ...state,
        loading: false,
        changelogs: action.payload,
      };

    case "CHANGELOGS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
