// Filename: reducers/pagesReducers.js

const initialState = {
  pages: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  pageDetail: {
    id: null,
    url: "",
    title: "",
    created_at: "",
    updated_at: "",
    changelogs: [], // Remove this if it was here previously
  },
  pageChangelogs: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  pageKeywords: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  loading: false,
  error: null,
  loadingDetail: false,
  errorDetail: null,
  loadingPageChangelogs: false,
  errorPageChangelogs: null,
  loadingPageKeywords: false,
  errorPageKeywords: null,
};

export default function pagesReducer(state = initialState, action) {
  switch (action.type) {
    case "PAGES_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "PAGES_SUCCESS":
      return {
        ...state,
        loading: false,
        pages: action.payload,
      };

    case "PAGES_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "PAGE_DETAIL_LOADING":
      return {
        ...state,
        loadingDetail: true,
        errorDetail: null,
      };

    case "PAGE_DETAIL_SUCCESS":
      return {
        ...state,
        loadingDetail: false,
        pageDetail: action.payload,
      };

    case "PAGE_DETAIL_FAILURE":
      return {
        ...state,
        loadingDetail: false,
        errorDetail: action.error,
      };

    case "PAGE_CHANGELOGS_LOADING":
      return {
        ...state,
        loadingPageChangelogs: true,
        errorPageChangelogs: null,
      };

    case "PAGE_CHANGELOGS_SUCCESS":
      return {
        ...state,
        loadingPageChangelogs: false,
        pageChangelogs: action.payload,
      };

    case "PAGE_CHANGELOGS_FAILURE":
      return {
        ...state,
        loadingPageChangelogs: false,
        errorPageChangelogs: action.error,
      };

    case "PAGE_KEYWORDS_LOADING":
      return {
        ...state,
        loadingPageKeywords: true,
        errorPageKeywords: null,
      };

    case "PAGE_KEYWORDS_SUCCESS":
      return {
        ...state,
        loadingPageKeywords: false,
        pageKeywords: action.payload,
      };

    case "PAGE_KEYWORDS_FAILURE":
      return {
        ...state,
        loadingPageKeywords: false,
        errorPageKeywords: action.error,
      };

    default:
      return state;
  }
}
