// Filename: AddEditProjectModal\KeywordsTab.js
import React, { useState, useEffect } from "react";
import { Form, Input, Badge } from "antd";
import { useTranslation } from "react-i18next";

const KeywordsTab = ({ form }) => {
  const { t } = useTranslation("projects");
  const [brandKeywordCount, setBrandKeywordCount] = useState(0);
  const [informationalKeywordCount, setInformationalKeywordCount] = useState(0);
  const [transactionalKeywordCount, setTransactionalKeywordCount] = useState(0);
  const [commercialKeywordCount, setCommercialKeywordCount] = useState(0);

  const keywords = [
    Form.useWatch("keywords_brand", form),
    Form.useWatch("keywords_intent_informational", form),
    Form.useWatch("keywords_intent_transactional", form),
    Form.useWatch("keywords_intent_commercial", form),
  ];

  const fields = [
    {
      label: t("projects-keywords.label.keywords_brand"),
      name: "keywords_brand",
      count: brandKeywordCount,
    },
    {
      label: t("projects-keywords.label.keywords_intent_informational"),
      name: "keywords_intent_informational",
      count: informationalKeywordCount,
    },
    {
      label: t("projects-keywords.label.keywords_intent_transactional"),
      name: "keywords_intent_transactional",
      count: transactionalKeywordCount,
    },
    {
      label: t("projects-keywords.label.keywords_intent_commercial"),
      name: "keywords_intent_commercial",
      count: commercialKeywordCount,
    },
  ];

  const calculateCount = (value) => {
    const valueWithNewlines = value.split(",").join("\n");
    return valueWithNewlines.split("\n").filter((row) => row.trim() !== "")
      .length;
  };

  const handleTextAreaChange = (field, value) => {
    if (value.includes(",")) {
      const newValue = value.replace(/,/g, "\n");
      form.setFieldsValue({ [field]: newValue });
      value = newValue;
    }

    const count = calculateCount(value);

    switch (field) {
      case "keywords_brand":
        setBrandKeywordCount(count);
        break;
      case "keywords_intent_informational":
        setInformationalKeywordCount(count);
        break;
      case "keywords_intent_transactional":
        setTransactionalKeywordCount(count);
        break;
      case "keywords_intent_commercial":
        setCommercialKeywordCount(count);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const brandValue = form.getFieldValue("keywords_brand") || "";
    const informationalValue =
      form.getFieldValue("keywords_intent_informational") || "";
    const transactionalValue =
      form.getFieldValue("keywords_intent_transactional") || "";
    const commercialValue =
      form.getFieldValue("keywords_intent_commercial") || "";

    setBrandKeywordCount(calculateCount(brandValue));
    setInformationalKeywordCount(calculateCount(informationalValue));
    setTransactionalKeywordCount(calculateCount(transactionalValue));
    setCommercialKeywordCount(calculateCount(commercialValue));
  }, [keywords]);

  return (
    <>
      {fields.map((field) => (
        <Form.Item
          key={field.name}
          label={
            <>
              <span>
                {field.label}
                <Badge
                  count={field.count}
                  overflowCount={5000}
                  style={{ marginLeft: "10px", backgroundColor: "#0652DD" }}
                />
              </span>
            </>
          }
          name={field.name}
          rules={[
            {
              required: true,
              message: t("projects-keywords.validation.keywords_required", {
                keywords_type: field.label.toLowerCase(),
              }),
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            onChange={(e) => handleTextAreaChange(field.name, e.target.value)}
          />
        </Form.Item>
      ))}
    </>
  );
};

export default KeywordsTab;
