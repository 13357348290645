import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeams } from "../actions/teamsActions";
import { Card, Button, Row, Col, Typography, Empty } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CreateTeamModal from "./CreateTeamModal";
import TeamAvatar from "./TeamAvatar";

const TeamsList = () => {
  const { t } = useTranslation("teams");
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.teams.teams);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  return (
    <div className="teams-list">
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <Typography.Title level={2}>{t("teams-list.title")}</Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            {t("teams-list.button.create")}
          </Button>
        </Col>
      </Row>

      {/* Conditional rendering for when there are no teams */}
      {teams.length === 0 ? (
        <Empty
          description={<span>{t("teams-list.empty.description")}</span>}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        <Row gutter={[16, 16]}>
          {teams.map((team) => (
            <Col xs={24} sm={12} md={8} lg={6} key={team.id}>
              <Link to={`/teams/${team.id}`}>
                <Card hoverable className="team-tile">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <TeamAvatar
                      image={team.image}
                      name={team.name}
                      primaryColor={team.primary_color}
                      size={96}
                    />
                    <Typography.Title level={2}>{team.name}</Typography.Title>
                  </div>
                  <Row>
                    <Col span={12}>
                      <strong>{t("teams-list.label.owner")}:</strong>
                    </Col>
                    <Col span={12}>
                      {`${team.owner.user.first_name} ${team.owner.user.last_name}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <strong>{t("teams-list.label.created")}:</strong>
                    </Col>
                    <Col span={12}>
                      {new Date(team.created).toLocaleDateString()}
                    </Col>
                  </Row>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      )}

      {/* Use the new modal component */}
      <CreateTeamModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default TeamsList;
