// components/AddEditProjectModal/IntegrationsTab.js

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, message, Select, Spin, Alert } from "antd";
import { useTranslation } from "react-i18next";
import {
  startGSCOAuth,
  fetchGSCProperties,
  selectGSCPropertyAction,
} from "../../actions/projectsActions";

const { Option } = Select;

const IntegrationsTab = ({ project }) => {
  const { t } = useTranslation("projects");
  const dispatch = useDispatch();

  const [gscProperties, setGscProperties] = useState([]);
  const [gscLoading, setGscLoading] = useState(false);
  const [gscError, setGscError] = useState(null);

  const isProjectSaved = project && project.id;

  // Function to fetch GSC properties
  const fetchGscProperties = async () => {
    setGscLoading(true);
    setGscError(null);
    try {
      const data = await dispatch(fetchGSCProperties(project.id));
      setGscProperties(data.sites);
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.message;
      setGscError(errorMessage);
    } finally {
      setGscLoading(false);
    }
  };

  // On component mount, fetch GSC properties if credentials are linked
  useEffect(() => {
    if (isProjectSaved) {
      fetchGscProperties();
    }
  }, [isProjectSaved]);

  // Check if we have returned from OAuth
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("gsc_oauth") === "success" && isProjectSaved) {
      fetchGscProperties();
      // Optionally, remove the gsc_oauth parameter from the URL
      params.delete("gsc_oauth");
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [isProjectSaved]);

  const handleGrantAccess = () => {
    if (isProjectSaved) {
      dispatch(startGSCOAuth(project.id));
    } else {
      message.error(t("projects-integrations.message.save_project_first"));
    }
  };

  const handleSelectProperty = async (selectedProperty) => {
    try {
      await dispatch(selectGSCPropertyAction(project.id, selectedProperty));
      message.success(t("projects-integrations.gsc_property_selected"));
      // Optionally, update the project data to reflect the selected property
    } catch (error) {
      message.error(`Failed to select GSC property: ${error.message}`);
    }
  };

  return (
    <>
      <Form.Item label={t("projects-integrations.label.google_search_console")}>
        {gscLoading ? (
          <Spin />
        ) : gscProperties?.length > 0 ? (
          <>
            <Form.Item>
              <Select
                placeholder={t(
                  "projects-integrations.placeholder.select_gsc_property"
                )}
                onChange={handleSelectProperty}
                style={{ width: "100%" }}
                defaultValue={
                  project?.data_sources?.find((ds) => ds.source_type === "gsc")
                    ?.selected_property
                }
              >
                {gscProperties.map((property) => (
                  <Option key={property} value={property}>
                    {property}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              type="link"
              onClick={handleGrantAccess}
              style={{ padding: 0 }}
            >
              {t("projects-integrations.button.reconnect_gsc_account")}
            </Button>
          </>
        ) : gscError ? (
          <>
            <Alert
              message={gscError}
              type="error"
              showIcon
              style={{ marginBottom: "16px" }}
            />
            <Button
              type="primary"
              onClick={handleGrantAccess}
              disabled={!isProjectSaved}
            >
              {t("projects-integrations.button.grant_read_only")}
            </Button>
          </>
        ) : (
          <Button
            type="primary"
            onClick={handleGrantAccess}
            disabled={!isProjectSaved}
          >
            {t("projects-integrations.button")}
          </Button>
        )}
        {!isProjectSaved && (
          <div style={{ color: "red" }}>
            {t("projects-integrations.message.save_project_first")}
          </div>
        )}
      </Form.Item>
    </>
  );
};

export default IntegrationsTab;
