// Filename: reducers/keywordsReducers.js

const initialState = {
  keywords: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  keywordDetail: {
    id: null,
    keyword_text: "",
    project: {},
    pages: [],
    created_at: "",
    updated_at: "",
  },
  keywordPages: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  loading: false,
  error: null,
  loadingDetail: false,
  errorDetail: null,
  loadingKeywordPages: false,
  errorKeywordPages: null,
};

export default function keywordsReducer(state = initialState, action) {
  switch (action.type) {
    case "KEYWORDS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "KEYWORDS_SUCCESS":
      return {
        ...state,
        loading: false,
        keywords: action.payload,
      };

    case "KEYWORDS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "KEYWORD_DETAIL_LOADING":
      return {
        ...state,
        loadingDetail: true,
        errorDetail: null,
      };

    case "KEYWORD_DETAIL_SUCCESS":
      return {
        ...state,
        loadingDetail: false,
        keywordDetail: action.payload,
      };

    case "KEYWORD_DETAIL_FAILURE":
      return {
        ...state,
        loadingDetail: false,
        errorDetail: action.error,
      };

    case "KEYWORD_PAGES_LOADING":
      return {
        ...state,
        loadingKeywordPages: true,
        errorKeywordPages: null,
      };

    case "KEYWORD_PAGES_SUCCESS":
      return {
        ...state,
        loadingKeywordPages: false,
        keywordPages: action.payload,
      };

    case "KEYWORD_PAGES_FAILURE":
      return {
        ...state,
        loadingKeywordPages: false,
        errorKeywordPages: action.error,
      };

    default:
      return state;
  }
}
