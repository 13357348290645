// Filename: api/keywordsApi.js

import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Fetch keywords for a project with optional filters, search, and ordering
export const fetchKeywords = async (projectId, params = {}) => {
  const response = await apiClient.get(`/keywords/`, {
    params: { projectid: projectId, ...params },
  });
  return response.data;
};

// Fetch detail for a specific keyword
export const fetchKeywordDetail = async (keywordId) => {
  const response = await apiClient.get(`/keywords/${keywordId}/`);
  return response.data;
};

// Fetch project users (for filtering)
export const fetchProjectUsers = async (projectId) => {
  const response = await apiClient.get(`/projects/${projectId}/users/`);
  return response.data;
};
