import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Button, Row, Col, List, Spin, Typography, Modal, message } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  PlayCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  getProjects,
  deleteExistingProject,
  startProjectScrape,
  startProjectDataFetch,
} from "../actions/projectsActions";
import AddEditProjectModal from "./AddEditProjectModal/AddEditProjectModal";
import { getScrapeProgress } from "../api/scrapeApi"; // Adjust the import path as needed

const ProjectsList = () => {
  const { t } = useTranslation("projects");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentTeam = useSelector((state) => state.teams.currentTeam);
  const { projects, loading } = useSelector((state) => state.projects);

  const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [scrapeProgress, setScrapeProgress] = useState({});
  const [dataFetchProgress, setDataFetchProgress] = useState({});

  const { projectId: routeProjectId, "*": routePath } = useParams();

  useEffect(() => {
    if (currentTeam?.id) {
      dispatch(getProjects(currentTeam.id));
    }
  }, [currentTeam, dispatch]);

  // Effect to handle route-based modal opening
  useEffect(() => {
    // Check if the current path matches the GSC property selection
    if (routeProjectId && routePath.endsWith("gsc/select-property")) {
      const project = projects.find(
        (p) => p.id === parseInt(routeProjectId, 10)
      );
      if (project) {
        setSelectedProject(project);
        setIsAddEditModalVisible(true);
      }
    }
  }, [routeProjectId, routePath, projects]);

  useEffect(() => {
    let interval;
    const fetchScrapeProgress = async () => {
      if (projects && projects.length > 0) {
        const promises = projects.map((project) =>
          getScrapeProgress(project.id)
            .then((data) => ({ projectId: project.id, data }))
            .catch((error) => {
              // Handle error, possibly no running scrape for this project
              return { projectId: project.id, data: null };
            })
        );

        const results = await Promise.all(promises);

        setScrapeProgress((prevProgress) => {
          const updatedProgress = { ...prevProgress };
          results.forEach(({ projectId, data }) => {
            if (data && data.status === "running") {
              updatedProgress[projectId] = data;
            } else {
              delete updatedProgress[projectId];
            }
          });
          return updatedProgress;
        });
      }
    };

    fetchScrapeProgress(); // Fetch progress immediately on component mount

    interval = setInterval(() => {
      fetchScrapeProgress();
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [projects]);

  const handleDelete = (id) => {
    Modal.confirm({
      title: t("projects-list.delete.title"),
      content: t("projects-list.delete.content"),
      okText: t("projects-list.delete.ok_text"),
      okType: "danger",
      cancelText: t("projects-list.delete.cancel_text"),
      onOk: async () => {
        try {
          await dispatch(deleteExistingProject(id));
          message.success(t("projects-list.delete.success"));
        } catch (error) {
          message.error(
            t("projects-list.delete.error", {
              error: error.response?.data?.detail || error.message,
            })
          );
        }
      },
    });
  };

  const handleEdit = (project) => {
    setSelectedProject(project);
    setIsAddEditModalVisible(true);
  };

  const handleScrape = async (projectId) => {
    try {
      await dispatch(startProjectScrape(projectId));
      message.success(t("projects-list.scrape.success"));

      // Fetch initial progress for the project immediately
      try {
        const data = await getScrapeProgress(projectId);
        setScrapeProgress((prevProgress) => ({
          ...prevProgress,
          [projectId]: data,
        }));
      } catch (error) {
        // Handle error if unable to fetch progress
      }
    } catch (error) {
      message.error(
        t("projects-list.scrape.error", {
          error: error.response?.data?.detail || error.message,
        })
      );
    }
  };

  const handleDataFetch = async (projectId) => {
    try {
      await dispatch(startProjectDataFetch(projectId));
      message.success(t("projects-list.data_fetch.success"));
    } catch (error) {
      message.error(
        t("projects-list.data_fetch.error", {
          error: error.response?.data?.detail || error.message,
        })
      );
    }
  };

  if (loading) {
    return (
      <div className="projects-list-container" style={{ textAlign: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="projects-list-container">
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <Typography.Title level={2}>
            {t("projects-list.title")}
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setSelectedProject(null);
              setIsAddEditModalVisible(true);
            }}
          >
            {t("projects-list.button.create")}
          </Button>
        </Col>
      </Row>

      <List
        itemLayout="horizontal"
        dataSource={projects}
        renderItem={(project) => {
          const progress = scrapeProgress[project.id];
          const isScraping = progress && progress.status === "running";

          return (
            <List.Item
              actions={[
                <Button
                  icon={
                    isScraping ? <LoadingOutlined /> : <PlayCircleOutlined />
                  }
                  onClick={() => handleScrape(project.id)}
                  disabled={isScraping}
                >
                  {isScraping
                    ? t("projects-list.button.scrape.running")
                    : t("projects-list.button.scrape.start")}
                </Button>,
                <Button
                  icon={<SyncOutlined />}
                  onClick={() => handleDataFetch(project.id)}
                >
                  {t("projects-list.button.data_fetch.start")}
                </Button>,
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(project)}
                >
                  {t("projects-list.button.edit")}
                </Button>,
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(project.id)}
                >
                  {t("projects-list.button.delete")}
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  project.favicon && (
                    <img
                      src={`data:image/png;base64,${project.favicon}`}
                      alt="Favicon"
                      style={{ width: 32, height: 32 }}
                    />
                  )
                }
                title={project.name}
                description={
                  <>
                    <div>{project.url}</div>
                    {isScraping && (
                      <div>
                        <Typography.Text type="secondary">
                          {t("projects-list.scrape.progress", {
                            pagesScraped: progress.pages_scraped,
                            // Include pages_total if available
                          })}
                        </Typography.Text>
                      </div>
                    )}
                  </>
                }
              />
            </List.Item>
          );
        }}
      />

      <AddEditProjectModal
        isOpen={isAddEditModalVisible}
        onClose={() => setIsAddEditModalVisible(false)}
        project={selectedProject}
        initialActiveTab={
          selectedProject?.id === parseInt(routeProjectId) &&
          routePath.endsWith("gsc/select-property")
            ? "4"
            : "1"
        }
      />
    </div>
  );
};

export default ProjectsList;
