// components/ExperimentDetail.js

import React, { useEffect, useState } from "react";
import { Typography, Button, Spin, message, Divider, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getExperimentDetail,
  updateExperimentStatusAction,
} from "../actions/experimentsActions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";

const ExperimentDetail = () => {
  const dispatch = useDispatch();
  const { experimentDetail, loadingDetail, errorDetail } = useSelector(
    (state) => state.experiments
  );
  const { t } = useTranslation("experiments");
  const { experimentId } = useParams();

  useEffect(() => {
    if (experimentId) {
      dispatch(getExperimentDetail(experimentId));
    }
  }, [dispatch, experimentId]);

  useEffect(() => {
    if (errorDetail) {
      message.error(t("experiment-detail.error.loading_failed"));
    }
  }, [errorDetail, t]);

  const handleUpdateStatus = (status) => {
    dispatch(updateExperimentStatusAction(experimentId, status))
      .then(() => {
        message.success(t("experiment-detail.success.status_updated"));
        // Refresh the experiment detail
        dispatch(getExperimentDetail(experimentId));
      })
      .catch((err) => {
        message.error(t("experiment-detail.error.status_update_failed"));
        console.error(err);
      });
  };

  return (
    <div>
      {loadingDetail ? (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {experimentDetail ? (
            <>
              <Typography.Title level={2}>
                {experimentDetail.experiment_name}
              </Typography.Title>
              <Button
                type="primary"
                onClick={() => handleUpdateStatus(!experimentDetail.successful)}
              >
                {experimentDetail.successful
                  ? t("experiment-detail.action.mark_unsuccessful")
                  : t("experiment-detail.action.mark_successful")}
              </Button>
              <Divider />
              <Typography.Paragraph>
                <strong>{t("experiment-detail.label.experiment_type")}:</strong>{" "}
                {experimentDetail.experiment_type}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <strong>{t("experiment-detail.label.hypothesis")}:</strong>{" "}
                {experimentDetail.hypothesis}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <strong>{t("experiment-detail.label.control_period")}:</strong>{" "}
                {moment(experimentDetail.control_start_date).format(
                  "YYYY-MM-DD"
                )}{" "}
                -{" "}
                {moment(experimentDetail.control_end_date).format("YYYY-MM-DD")}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <strong>{t("experiment-detail.label.test_period")}:</strong>{" "}
                {moment(experimentDetail.test_start_date).format("YYYY-MM-DD")}{" "}
                - {moment(experimentDetail.test_end_date).format("YYYY-MM-DD")}
              </Typography.Paragraph>
              {/* Add more details as needed */}
            </>
          ) : (
            <Typography.Text>
              {t("experiment-detail.no_experiment")}
            </Typography.Text>
          )}
        </>
      )}
    </div>
  );
};

export default ExperimentDetail;
