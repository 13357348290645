// Filename: routers/KeywordsRouter.js

import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../../../core/accounts/routers/ProtectedRoute";
import PrivateLayout from "../../../core/layout/components/PrivateLayout";

import KeywordsList from "../components/KeywordsList";
import KeywordDetail from "../components/KeywordDetail";

const KeywordsRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PrivateLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<KeywordsList />} />
        <Route path="/:keywordId/" element={<KeywordDetail />} />
      </Route>
    </Routes>
  );
};

export default KeywordsRouter;
