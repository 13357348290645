import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../../../core/accounts/routers/ProtectedRoute";
import PrivateLayout from "../../../core/layout/components/PrivateLayout";

import ChangelogsList from "../components/ChangelogsList";

const ChangelogsRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PrivateLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<ChangelogsList />} />
      </Route>
    </Routes>
  );
};

export default ChangelogsRouter;
