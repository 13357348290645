import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Card,
  Button,
  Row,
  Col,
  Typography,
  Space,
  message,
  Radio,
  Tag,
} from "antd";
import { createSubscription } from "../actions/teamsActions";
import { useResponsive } from "../../layout/hooks/useResponsive";

const NoSubscription = ({ team }) => {
  const { t } = useTranslation("teams");
  const dispatch = useDispatch();
  const [billingInterval, setBillingInterval] = useState("monthly"); // Default to 'monthly'

  const { mdDown } = useResponsive();

  const plans = [
    {
      name: "Starter",
      title: t("no-subscription.plan.one.title"),
      price:
        billingInterval === "monthly"
          ? t("no-subscription.plan.one.price.monthly")
          : t("no-subscription.plan.one.price.yearly"),
      description:
        billingInterval === "monthly"
          ? t("no-subscription.plan.one.description.monthly")
          : t("no-subscription.plan.one.description.yearly"),
      features: [
        t("no-subscription.plan.one.features.0"),
        t("no-subscription.plan.one.features.1"),
        t("no-subscription.plan.one.features.2"),
        t("no-subscription.plan.one.features.3"),
        t("no-subscription.plan.one.features.4"),
      ],
      button: t("no-subscription.plan.one.button"),
    },
    {
      name: "Growth",
      title: t("no-subscription.plan.two.title"),
      price:
        billingInterval === "monthly"
          ? t("no-subscription.plan.two.price.monthly")
          : t("no-subscription.plan.two.price.yearly"),
      description:
        billingInterval === "monthly"
          ? t("no-subscription.plan.two.description.monthly")
          : t("no-subscription.plan.two.description.yearly"),
      features: [
        t("no-subscription.plan.two.features.0"),
        t("no-subscription.plan.two.features.1"),
        t("no-subscription.plan.two.features.2"),
        t("no-subscription.plan.two.features.3"),
        t("no-subscription.plan.two.features.4"),
      ],
      button: t("no-subscription.plan.two.button"),
    },
    {
      name: "Scale",
      title: t("no-subscription.plan.three.title"),
      price:
        billingInterval === "monthly"
          ? t("no-subscription.plan.three.price.monthly")
          : t("no-subscription.plan.three.price.yearly"),
      description:
        billingInterval === "monthly"
          ? t("no-subscription.plan.three.description.monthly")
          : t("no-subscription.plan.three.description.yearly"),
      features: [
        t("no-subscription.plan.three.features.0"),
        t("no-subscription.plan.three.features.1"),
        t("no-subscription.plan.three.features.2"),
        t("no-subscription.plan.three.features.3"),
        t("no-subscription.plan.three.features.4"),
      ],
      button: t("no-subscription.plan.three.button"),
    },
  ];

  const handleSubscribe = async (planName) => {
    try {
      console.log("TEAM: ", team.id);
      console.log("Billing Interval: ", billingInterval);
      const data = await dispatch(
        createSubscription(team.id, planName, billingInterval)
      );
      window.location.href = data.checkout_url;
    } catch (error) {
      message.error(t("no-subscription.error.initiate"));
    }
  };

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Card title={t("no-subscription.title")}>
          <Typography.Paragraph>
            {t("no-subscription.text")}
          </Typography.Paragraph>
        </Card>

        <Row justify="center">
          <Radio.Group
            onChange={(e) => setBillingInterval(e.target.value)}
            defaultValue="monthly"
            style={{ marginBottom: "15px" }}
          >
            <Radio.Button value="monthly">
              {t("no-subscription.billing.monthly")}
            </Radio.Button>
            <Radio.Button value="yearly">
              {t("no-subscription.billing.yearly")}{" "}
              <Tag
                color="var(--primary-color)"
                style={{ marginLeft: 8, marginRight: 0 }}
              >
                {t("no-subscription.billing.yearly_discount")}
              </Tag>
            </Radio.Button>
          </Radio.Group>
        </Row>

        <Row gutter={32} justify="center">
          {plans.map((plan, index) => (
            <Col
              key={index}
              xs={24}
              md={24}
              lg={8}
              style={{ marginBottom: mdDown ? "8px" : "0px" }}
            >
              <Card style={{ textAlign: "center" }}>
                <Typography.Title level={3}>{plan.title}</Typography.Title>
                <Typography.Title level={1} style={{ margin: "0" }}>
                  {plan.price}
                </Typography.Title>
                <Typography.Text type="secondary">
                  {plan.description}
                </Typography.Text>
                <ul
                  style={{
                    listStyleType: "none",
                    padding: 0,
                    margin: "20px 0",
                  }}
                >
                  {plan.features.map((feature, idx) => (
                    <li key={idx} style={{ marginBottom: "10px" }}>
                      {feature}
                    </li>
                  ))}
                </ul>

                <Button
                  type="primary"
                  block
                  onClick={() => handleSubscribe(plan.name)}
                >
                  {plan.button}
                </Button>
              </Card>
            </Col>
          ))}
        </Row>
      </Space>
    </div>
  );
};

export default NoSubscription;
