// Filename: api/statsApi.js

import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

/**
 * Fetch statistics for a specific project with optional parameters.
 *
 * @param {number} projectId - The ID of the project.
 * @param {Object} params - Query parameters for filtering and aggregation.
 * @returns {Promise<Object>} - The statistics data.
 */
export const fetchStatistics = async (projectId, params = {}) => {
  try {
    const response = await apiClient.get(`/stats/`, {
      params: { projectid: projectId, ...params },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching project statistics:", error);
    throw error;
  }
};
