// components\PrivateLayout.js

import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setThemeSettings } from "../actions/layoutActions";
import { Layout, Menu, Button, theme } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
  ProjectOutlined,
  HistoryOutlined,
  FileOutlined,
  TagOutlined,
  ExperimentOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { useResponsive } from "../hooks/useResponsive";
import logoLight from "../../../../assets/logo-light.png";
import logoDark from "../../../../assets/logo-dark.png";
import iconLight from "../../../../assets/icon-light.png";
import iconDark from "../../../../assets/icon-dark.png";
import NotificationCenter from "../../notifications/components/NotificationCenter";
import AvatarDropdown from "./AvatarDropdown";
import TeamDropdown from "../../teams/components/TeamDropdown";
import { useTranslation } from "react-i18next";

import ProjectDropdown from "../../../app/projects/components/ProjectDropdown";

const SITE_NAME = process.env.REACT_APP_SITE_NAME;

const { Header, Content, Footer, Sider } = Layout;

const PrivateLayout = () => {
  const { t } = useTranslation("layout");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const themeMode = useSelector((state) => state.layout.themeMode);
  const themeSettings = useSelector((state) => state.layout.themeSettings); // Get theme settings from Redux
  const currentTeam = useSelector((state) => state.teams.currentTeam);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { mdDown } = useResponsive();

  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);

  useEffect(() => {
    // Update theme settings when the current team changes
    if (currentTeam) {
      dispatch(
        setThemeSettings({
          primaryColor: currentTeam.primary_color || "#0652DD",
          secondaryColor: currentTeam.secondary_color || "#ff4d4f",
        })
      );
    }
  }, [currentTeam, dispatch]);

  // Apply theme settings directly from Redux
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--primary-color", themeSettings.primaryColor);
    root.style.setProperty("--secondary-color", themeSettings.secondaryColor);
  }, [themeSettings]);

  const toggleMenu = () => {
    if (mdDown) {
      setCollapsedWidth(collapsedWidth === 0 ? 80 : 0);
    } else {
      setCollapsed(!collapsed);
    }
  };

  const menuIcon = mdDown ? (
    collapsedWidth === 0 ? (
      <MenuUnfoldOutlined />
    ) : (
      <MenuFoldOutlined />
    )
  ) : collapsed ? (
    <MenuUnfoldOutlined />
  ) : (
    <MenuFoldOutlined />
  );

  useEffect(() => {
    const updateLayout = () => {
      if (mdDown) {
        setCollapsed(true);
        setCollapsedWidth(0);
      } else {
        setCollapsedWidth(80);
      }
    };

    updateLayout();

    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, [mdDown]);

  const menuItems = [
    {
      key: "/dashboard",
      icon: <DashboardOutlined />,
      label: <Link to="/dashboard">{t("private.link.dashboard")}</Link>,
    },
    {
      key: "/account/profile",
      icon: <UserOutlined />,
      label: <Link to="/account/profile">{t("private.link.profile")}</Link>,
    },
    {
      key: "/teams/",
      icon: <TeamOutlined />,
      label: <Link to="/teams/">{t("private.link.teams")}</Link>,
    },
    {
      key: "/projects/",
      icon: <ProjectOutlined />,
      label: <Link to="/projects/">{t("private.link.projects")}</Link>,
    },
    {
      key: "/changelogs/",
      icon: <HistoryOutlined />,
      label: <Link to="/changelogs/">{t("private.link.changelogs")}</Link>,
    },
    {
      key: "/pages/",
      icon: <FileOutlined />,
      label: <Link to="/pages/">{t("private.link.pages")}</Link>,
    },
    {
      key: "/keywords/",
      icon: <TagOutlined />,
      label: <Link to="/keywords/">{t("private.link.keywords")}</Link>,
    },
    {
      key: "/experiments/",
      icon: <ExperimentOutlined />,
      label: <Link to="/experiments/">{t("private.link.experiments")}</Link>,
    },
  ];

  const logoSrc = collapsed
    ? themeMode === "dark"
      ? iconDark
      : iconLight
    : themeMode === "dark"
    ? logoDark
    : logoLight;

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          theme={themeMode}
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={collapsedWidth}
        >
          <img
            className="logo"
            src={logoSrc}
            style={{
              maxHeight: "48px",
              maxWidth: "100%",
              margin: "8px auto",
              display: "block",
            }}
            onClick={() => navigate("/")}
            alt="Logo"
          />
          <div
            style={{
              padding: "5px",
              position: "relative",
            }}
          >
            <ProjectDropdown collapsed={collapsed} />
          </div>
          <Menu
            theme={themeMode}
            mode="inline"
            selectedKeys={[location.pathname]}
            items={menuItems}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: "0 16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: colorBgContainer,
            }}
          >
            <Button
              type="text"
              icon={menuIcon}
              onClick={toggleMenu}
              style={{
                fontSize: "16px",
              }}
            />
            <span></span>
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              <NotificationCenter />
              <AvatarDropdown />
            </div>
          </Header>
          <Content
            style={{
              margin: "16px",
            }}
          >
            <div
              style={{
                padding: "24px",
                minHeight: "360px",
                backgroundColor: colorBgContainer,
              }}
            >
              <Outlet />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>{SITE_NAME} ©2024</Footer>
        </Layout>
      </Layout>
    </>
  );
};

export default PrivateLayout;
