import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../../../core/accounts/routers/ProtectedRoute";
import PrivateLayout from "../../../core/layout/components/PrivateLayout";

import ProjectsList from "../components/ProjectsList";

const ProjectsRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PrivateLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<ProjectsList />} />
        <Route
          path="/:projectId/gsc/select-property"
          element={<ProjectsList />}
        />
      </Route>
    </Routes>
  );
};

export default ProjectsRouter;
