// Filename: components/PageDetail.js

import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Spin,
  Typography,
  message,
  Empty,
  Divider,
  Segmented,
  Row,
  Col,
} from "antd";
import {
  getPageDetail,
  getPageChangeLogs,
  getPageKeywords, // Import the getPageKeywords action
} from "../actions/pagesActions";
import PageChangelogFilter from "./PageChangelogFilter";
import PageKeywordFilter from "./PageKeywordFilter"; // Import the PageKeywordFilter component
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Line } from "@ant-design/charts"; // Import Line chart from Ant Design Charts
import { fetchStatistics } from "../api/statsApi"; // Import the new API function

const PageDetail = () => {
  const dispatch = useDispatch();
  const {
    pageDetail,
    pageChangelogs,
    pageKeywords, // Destructure pageKeywords from state
    loadingDetail,
    loadingPageChangelogs,
    loadingPageKeywords, // Add loading state for keywords
    errorDetail,
    errorPageChangelogs,
    errorPageKeywords, // Add error state for keywords
  } = useSelector((state) => state.pages);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const { t } = useTranslation("pages");
  const { pageId } = useParams();

  const [changelogPage, setChangelogPage] = useState(1);
  const [changelogPageSize, setChangelogPageSize] = useState(20);
  const [changelogFilters, setChangelogFilters] = useState({});
  const [changelogOrdering, setChangelogOrdering] = useState("-created_at");

  // State for charts
  const [chartPeriod, setChartPeriod] = useState("3 Months"); // Default period
  const [chartAggregation, setChartAggregation] = useState("Day"); // Default aggregation
  const [chartData, setChartData] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);

  // State for keywords
  const [keywordPage, setKeywordPage] = useState(1);
  const [keywordPageSize, setKeywordPageSize] = useState(10);
  const [keywordOrdering, setKeywordOrdering] = useState("-created_at");
  const [keywordFilters, setKeywordFilters] = useState({});
  const [keywordStatsDateRange, setKeywordStatsDateRange] =
    useState("last_month");

  useEffect(() => {
    if (currentProject?.id && pageId) {
      dispatch(getPageDetail(pageId));
    }
  }, [currentProject, dispatch, pageId]);

  // Fetch changelogs when changelog-related state changes
  useEffect(() => {
    if (currentProject?.id && pageId) {
      fetchChangelogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentProject,
    pageId,
    changelogPage,
    changelogPageSize,
    changelogFilters,
    changelogOrdering,
  ]);

  // Fetch chart data when chart-related state changes
  useEffect(() => {
    if (currentProject?.id && pageId) {
      fetchChartData(); // Fetch chart data on chart-related state change
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, pageId, chartPeriod, chartAggregation]);

  // Fetch keywords when relevant state changes
  useEffect(() => {
    if (currentProject?.id && pageId) {
      fetchKeywords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentProject,
    pageId,
    keywordPage,
    keywordPageSize,
    keywordOrdering,
    keywordFilters,
    keywordStatsDateRange,
  ]);

  useEffect(() => {
    if (errorDetail) {
      message.error(t("error.loading_failed"));
    }
    if (errorPageChangelogs) {
      message.error(t("page-detail.changelog.error.loading_failed"));
    }
    if (errorPageKeywords) {
      message.error(t("page-detail.keywords.error.loading_failed"));
    }
  }, [errorDetail, errorPageChangelogs, errorPageKeywords, t]);

  const fetchChangelogs = () => {
    const params = {
      page: changelogPage,
      page_size: changelogPageSize,
      ordering: changelogOrdering,
      ...changelogFilters,
    };
    dispatch(getPageChangeLogs(pageId, params));
  };

  const fetchKeywords = () => {
    const params = {
      page: keywordPage,
      page_size: keywordPageSize,
      ordering: keywordOrdering,
      include: "stats",
      stats_date_range: keywordStatsDateRange,
      ...keywordFilters,
    };
    dispatch(getPageKeywords(currentProject.id, pageId, params));
  };

  const handleChangelogFilterChange = (newFilters) => {
    setChangelogFilters(newFilters);
    setChangelogPage(1); // Reset to first page when filters change
  };

  const handleKeywordFilterChange = (newFilters) => {
    setKeywordFilters(newFilters);
    setKeywordPage(1); // Reset to first page when filters change
  };

  const changelogColumns = useMemo(
    () => [
      {
        title: t("page-detail.changelog.label.created_at"),
        dataIndex: "created_at",
        key: "created_at",
        render: (text) => new Date(text).toLocaleString(),
        width: 180,
      },
      {
        title: t("page-detail.changelog.label.changetype"),
        dataIndex: ["changetype", "name"],
        key: "changetype",
        width: 150,
      },
      {
        title: t("page-detail.changelog.label.old_value"),
        dataIndex: "old_value",
        key: "old_value",
        render: (text) => text || t("page-detail.changelog.label.no_value"),
        width: 200,
      },
      {
        title: t("page-detail.changelog.label.new_value"),
        dataIndex: "new_value",
        key: "new_value",
        render: (text) => text || t("page-detail.changelog.label.no_value"),
        width: 200,
      },
      {
        title: t("page-detail.changelog.label.user"),
        dataIndex: ["user", "email"],
        key: "user",
        render: (text, record) =>
          record.user
            ? `${record.user.first_name} ${record.user.last_name}`
            : t("page-detail.changelog.label.no_value"),
        width: 150,
      },
    ],
    [t]
  );

  const keywordColumns = useMemo(
    () => [
      {
        title: t("page-detail.keywords.label.keyword_text"),
        dataIndex: "keyword_text",
        key: "keyword_text",
        sorter: true,
        render: (text, record) =>
          text ? (
            <Typography.Text>{text}</Typography.Text>
          ) : (
            t("page-detail.keywords.no_value")
          ),
        width: 250,
      },
      {
        title: t("page-detail.keywords.label.impressions"),
        dataIndex: "impressions",
        key: "impressions",
        sorter: true,
        render: (value) => value || 0,
        width: 150,
      },
      {
        title: t("page-detail.keywords.label.clicks"),
        dataIndex: "clicks",
        key: "clicks",
        sorter: true,
        render: (value) => value || 0,
        width: 150,
      },
      {
        title: t("page-detail.keywords.label.ctr"),
        dataIndex: "ctr",
        key: "ctr",
        sorter: true,
        render: (value) =>
          value !== undefined && value !== null
            ? `${(value * 100).toFixed(2)}%`
            : "0.00%",
        width: 150,
      },
      {
        title: t("page-detail.keywords.label.position"),
        dataIndex: "position",
        key: "position",
        sorter: true,
        render: (value) =>
          value !== undefined && value !== null ? value.toFixed(2) : "0.00",
        width: 150,
      },
    ],
    [t]
  );

  const handleChangelogTableChange = (pagination, filtersFromTable, sorter) => {
    setChangelogPage(pagination.current);
    setChangelogPageSize(pagination.pageSize);

    if (sorter.order) {
      const orderPrefix = sorter.order === "ascend" ? "" : "-";
      setChangelogOrdering(`${orderPrefix}${sorter.field}`);
    } else {
      setChangelogOrdering("-created_at");
    }
  };

  const handleKeywordTableChange = (pagination, filtersFromTable, sorter) => {
    setKeywordPage(pagination.current);
    setKeywordPageSize(pagination.pageSize);

    if (sorter.order) {
      const orderPrefix = sorter.order === "ascend" ? "" : "-";
      setKeywordOrdering(`${orderPrefix}${sorter.field}`);
    } else {
      setKeywordOrdering("-created_at");
    }
  };

  // Chart-related functions and states

  // Define the mapping for chart periods to date ranges
  const periodMapping = {
    "1 Month": {
      label: "1 Month",
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      endDate: new Date(),
    },
    "3 Months": {
      label: "3 Months",
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
      endDate: new Date(),
    },
    "6 Months": {
      label: "6 Months",
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
      endDate: new Date(),
    },
    "This Year": {
      label: "This Year",
      startDate: new Date(new Date().getFullYear(), 0, 1), // January 1st of current year
      endDate: new Date(),
    },
    "12 Months": {
      label: "12 Months",
      startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      endDate: new Date(),
    },
    "All Time": {
      label: "All Time",
      startDate: null, // Will fetch all records
      endDate: new Date(),
    },
  };

  const fetchChartData = async () => {
    setLoadingChart(true);
    try {
      // Determine start and end dates based on selected period
      const selectedPeriod = periodMapping[chartPeriod];
      let params = {
        level: "page",
        data_source: "gsc",
        aggregation: chartAggregation.toLowerCase(), // 'day', 'week', 'month'
        page_id: pageId,
      };

      if (selectedPeriod.startDate && selectedPeriod.endDate) {
        params.date_range = "specific";
        // Format dates as YYYY-MM-DD
        params.start_date = selectedPeriod.startDate
          .toISOString()
          .split("T")[0];
        params.end_date = selectedPeriod.endDate.toISOString().split("T")[0];
      } else {
        params.date_range = "all";
      }

      // Use the fetchProjectStatistics API function
      const data = await fetchStatistics(currentProject.id, params);

      // Transform data for the charts
      // Assuming data is a list of data_sources with their statistics
      // We'll combine them by period

      const combinedMetrics = {};

      data.forEach((source) => {
        source.statistics.forEach((entry) => {
          const period = entry.period;
          if (!combinedMetrics[period]) {
            combinedMetrics[period] = {
              period: period,
              impressions: 0,
              clicks: 0,
              ctr: 0,
              position: 0,
            };
          }
          combinedMetrics[period].impressions += entry.impressions || 0;
          combinedMetrics[period].clicks += entry.clicks || 0;
          combinedMetrics[period].ctr += entry.ctr || 0;
          combinedMetrics[period].position += entry.position || 0;
        });
      });

      // Convert combinedMetrics to an array and calculate average CTR and Position
      const chartDataArray = Object.values(combinedMetrics).map((item) => ({
        period: item.period,
        impressions: item.impressions,
        clicks: item.clicks,
        ctr: item.impressions > 0 ? item.clicks / item.impressions : 0,
        position: item.impressions > 0 ? item.position / item.impressions : 0,
      }));

      // Sort the chart data by period
      chartDataArray.sort((a, b) => new Date(a.period) - new Date(b.period));

      setChartData(chartDataArray);
    } catch (error) {
      console.error(error);
      message.error(t("error.loading_failed"));
    } finally {
      setLoadingChart(false);
    }
  };

  const handlePeriodChange = (value) => {
    setChartPeriod(value);
  };

  const handleAggregationChange = (value) => {
    setChartAggregation(value);
  };

  // Define chart configurations for each metric
  const chartConfigImpressions = useMemo(() => {
    return {
      data: chartData,
      xField: "period",
      yField: "impressions",
      color: "#1976D2",
      xAxis: {
        type: "time",
        mask: "YYYY-MM-DD",
      },
      yAxis: {
        title: {
          text: t("page-detail.statistics.impressions"),
        },
      },
      tooltip: {
        shared: true,
        showMarkers: false,
      },
      legend: {
        position: "top-left",
      },
    };
  }, [chartData, t]);

  const chartConfigClicks = useMemo(() => {
    return {
      data: chartData,
      xField: "period",
      yField: "clicks",
      color: "#FF5722",
      xAxis: {
        type: "time",
        mask: "YYYY-MM-DD",
      },
      yAxis: {
        title: {
          text: t("page-detail.statistics.clicks"),
        },
      },
      tooltip: {
        shared: true,
        showMarkers: false,
      },
      legend: {
        position: "top-left",
      },
    };
  }, [chartData, t]);

  const chartConfigCTR = useMemo(() => {
    return {
      data: chartData,
      xField: "period",
      yField: "ctr",
      color: "#4CAF50",
      xAxis: {
        type: "time",
        mask: "YYYY-MM-DD",
      },
      yAxis: {
        title: {
          text: t("page-detail.statistics.ctr"),
        },
        min: 0,
        max: 1,
        label: {
          formatter: (v) => `${(v * 100).toFixed(2)}%`,
        },
      },
      tooltip: {
        formatter: (datum) => ({
          name: t("page-detail.statistics.ctr"),
          value: `${(datum.ctr * 100).toFixed(2)}%`,
        }),
      },
      legend: {
        position: "top-left",
      },
    };
  }, [chartData, t]);

  const chartConfigPosition = useMemo(() => {
    return {
      data: chartData,
      xField: "period",
      yField: "position",
      color: "#9C27B0",
      xAxis: {
        type: "time",
        mask: "YYYY-MM-DD",
      },
      yAxis: {
        title: {
          text: t("page-detail.statistics.position"),
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        showMarkers: false,
      },
      legend: {
        position: "top-left",
      },
    };
  }, [chartData, t]);

  return (
    <div>
      {loadingDetail ? (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {pageDetail ? (
            <>
              <Typography.Title level={2}>
                {t("page-detail.title")}
              </Typography.Title>
              <Typography.Paragraph>
                <strong>{t("page-detail.label.url")}:</strong>{" "}
                <a
                  href={pageDetail.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pageDetail.url}
                </a>
              </Typography.Paragraph>
              <Typography.Paragraph>
                <strong>{t("page-detail.label.title")}:</strong>{" "}
                {pageDetail.title}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <strong>{t("page-detail.label.created_at")}:</strong>{" "}
                {new Date(pageDetail.created_at).toLocaleString()}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <strong>{t("page-detail.label.updated_at")}:</strong>{" "}
                {new Date(pageDetail.updated_at).toLocaleString()}
              </Typography.Paragraph>
              <Divider />

              {/* Chart Controls */}
              <Typography.Title level={3}>
                {t("page-detail.statistics.title")}
              </Typography.Title>
              <Row gutter={[16, 16]}>
                <Col>
                  <Typography.Text>
                    {t("page-detail.statistics.label.period")}:
                  </Typography.Text>{" "}
                  <Segmented
                    options={Object.keys(periodMapping)}
                    value={chartPeriod}
                    onChange={handlePeriodChange}
                  />
                </Col>
                <Col>
                  <Typography.Text>
                    {t("page-detail.statistics.label.aggregation")}:
                  </Typography.Text>{" "}
                  <Segmented
                    options={["Day", "Week", "Month"]}
                    value={chartAggregation}
                    onChange={handleAggregationChange}
                  />
                </Col>
              </Row>
              <Divider />

              {/* Line Charts */}
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                  <Typography.Title level={5}>
                    {t("page-detail.statistics.impressions")}
                  </Typography.Title>
                  {loadingChart ? (
                    <Spin />
                  ) : chartData.length > 0 ? (
                    <Line {...chartConfigImpressions} />
                  ) : (
                    <Empty description={t("page-detail.statistics.no_data")} />
                  )}
                </Col>
                <Col xs={24} lg={12}>
                  <Typography.Title level={5}>
                    {t("page-detail.statistics.clicks")}
                  </Typography.Title>
                  {loadingChart ? (
                    <Spin />
                  ) : chartData.length > 0 ? (
                    <Line {...chartConfigClicks} />
                  ) : (
                    <Empty description={t("page-detail.statistics.no_data")} />
                  )}
                </Col>
                <Col xs={24} lg={12}>
                  <Typography.Title level={5}>
                    {t("page-detail.statistics.ctr")}
                  </Typography.Title>
                  {loadingChart ? (
                    <Spin />
                  ) : chartData.length > 0 ? (
                    <Line {...chartConfigCTR} />
                  ) : (
                    <Empty description={t("page-detail.statistics.no_data")} />
                  )}
                </Col>
                <Col xs={24} lg={12}>
                  <Typography.Title level={5}>
                    {t("page-detail.statistics.position")}
                  </Typography.Title>
                  {loadingChart ? (
                    <Spin />
                  ) : chartData.length > 0 ? (
                    <Line {...chartConfigPosition} />
                  ) : (
                    <Empty description={t("page-detail.statistics.no_data")} />
                  )}
                </Col>
              </Row>

              <Divider />

              {/* Keywords Section */}
              <Typography.Title level={3}>
                {t("page-detail.keywords.title")}
              </Typography.Title>

              {/* Include the PageKeywordFilter */}
              <PageKeywordFilter onFilterChange={handleKeywordFilterChange} />
              <Divider />

              {pageKeywords?.results && pageKeywords.results.length > 0 ? (
                <Table
                  columns={keywordColumns}
                  dataSource={pageKeywords.results}
                  rowKey="id"
                  pagination={{
                    current: keywordPage,
                    pageSize: keywordPageSize,
                    total: pageKeywords.count,
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t(
                        "page-detail.keywords.pagination.showing"
                      )} ${total} ${t(
                        "page-detail.keywords.pagination.items"
                      )}`,
                  }}
                  onChange={handleKeywordTableChange}
                  bordered
                />
              ) : (
                <Empty description={t("page-detail.keywords.no_keywords")} />
              )}

              <Divider />

              <Typography.Title level={3}>
                {t("page-detail.changelog.title")}
              </Typography.Title>
              <PageChangelogFilter
                onFilterChange={handleChangelogFilterChange}
              />
              <Divider />
              {pageChangelogs?.results && pageChangelogs.results.length > 0 ? (
                <Table
                  columns={changelogColumns}
                  dataSource={pageChangelogs.results}
                  rowKey="id"
                  pagination={{
                    current: changelogPage,
                    pageSize: changelogPageSize,
                    total: pageChangelogs.count,
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t(
                        "page-detail.changelog.pagination.showing"
                      )} ${total} ${t(
                        "page-detail.changelog.pagination.items"
                      )}`,
                  }}
                  onChange={handleChangelogTableChange}
                  bordered
                />
              ) : (
                <Empty description={t("page-detail.changelog.no_changes")} />
              )}
            </>
          ) : (
            <Empty description={t("page-detail.no_page")} />
          )}
        </>
      )}
    </div>
  );
};

export default PageDetail;
