// routers/ExperimentsRouter.js

import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../../../core/accounts/routers/ProtectedRoute";
import PrivateLayout from "../../../core/layout/components/PrivateLayout";

import ExperimentsList from "../components/ExperimentsList";
import ExperimentDetail from "../components/ExperimentDetail";

const ExperimentsRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PrivateLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<ExperimentsList />} />
        <Route path=":experimentId/" element={<ExperimentDetail />} />
      </Route>
    </Routes>
  );
};

export default ExperimentsRouter;
