// api/experimentsApi.js

import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Fetch experiments for a project with optional filters, search, and ordering
export const fetchExperiments = async (projectId, params = {}) => {
  const response = await apiClient.get(`/experiments/`, {
    params: { projectid: projectId, ...params },
  });
  return response.data;
};

// Fetch detail for a specific experiment
export const fetchExperimentDetail = async (experimentId) => {
  const response = await apiClient.get(`/experiments/${experimentId}/`);
  return response.data;
};

// Create a new experiment
export const createExperiment = async (experimentData) => {
  const response = await apiClient.post(`/experiments/`, experimentData);
  return response.data;
};

// Update experiment status (mark as successful/unsuccessful)
export const updateExperimentStatus = async (experimentId, status) => {
  const endpoint = status ? "mark_successful" : "mark_unsuccessful";
  const response = await apiClient.post(
    `/experiments/${experimentId}/${endpoint}/`
  );
  return response.data;
};

// Delete an experiment
export const deleteExperiment = async (experimentId) => {
  const response = await apiClient.delete(`/experiments/${experimentId}/`);
  return response.data;
};
