import { getApiClient } from "../../accounts/api/apiUtils";

const apiClient = getApiClient();

export const createSubscriptionApi = async (
  organizationId,
  plan,
  billingInterval
) => {
  console.log("organizationId", organizationId);
  console.log("plan", plan);
  console.log("billingInterval", billingInterval);

  const response = await apiClient.post("/subscriptions/", {
    organization_id: organizationId,
    plan,
    billing_interval: billingInterval,
  });
  return response.data;
};

export const getSubscriptionByOrganizationId = async (organizationId) => {
  const response = await apiClient.get(
    `/subscriptions/?organization_id=${organizationId}`
  );
  return response.data[0]; // Assuming one subscription per organization
};

export const changeSubscriptionPlan = async (
  subscriptionId,
  newPlan,
  billingInterval
) => {
  const response = await apiClient.post(
    `/subscriptions/${subscriptionId}/change_plan/`,
    {
      new_plan: newPlan,
      billing_interval: billingInterval, // Pass the billing interval
    }
  );
  return response.data;
};

export const cancelSubscription = async (subscriptionId) => {
  const response = await apiClient.post(
    `/subscriptions/${subscriptionId}/cancel/`
  );
  return response.data;
};

export const resumeSubscription = async (subscriptionId) => {
  const response = await apiClient.post(
    `/subscriptions/${subscriptionId}/resume/`
  );
  return response.data;
};

export const getSubscription = async (subscriptionId) => {
  const response = await apiClient.get(
    `/subscriptions/${subscriptionId}/details/`
  );
  return response.data;
};
