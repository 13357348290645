import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Card,
  Typography,
  Row,
  Col,
  Progress,
  Button,
  Divider,
  Space,
  Tag,
  Table,
  message,
} from "antd";
import { QuestionOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  changePlan,
  cancelSubscriptionAction,
  resumeSubscriptionAction,
  fetchTeam,
} from "../actions/teamsActions";
import { getSubscription } from "../api/subscriptionsApi";
import { useResponsive } from "../../layout/hooks/useResponsive";

const SubscriptionDetails = ({ team }) => {
  const { t } = useTranslation("teams");
  const dispatch = useDispatch();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { mdUp } = useResponsive();

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const data = await getSubscription(team.subscription.id);
        console.log("data sub: ", data);
        setSubscriptionData(data);
        setLoading(false);
      } catch (error) {
        message.error(t("subscription-details.fetch.error"));
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, [team.subscription.id, t]);

  const handleCancelSubscription = async () => {
    try {
      await dispatch(cancelSubscriptionAction(team.subscription.id));
      message.success(t("subscription-details.cancel.success"));
      dispatch(fetchTeam(team.id));
    } catch (error) {
      message.error(t("subscription-details.cancel.error"));
    }
  };

  const handleResumeSubscription = async () => {
    try {
      await dispatch(resumeSubscriptionAction(team.subscription.id));
      message.success(t("subscription-details.resume.success"));
      dispatch(fetchTeam(team.id));
    } catch (error) {
      message.error(t("subscription-details.resume.error"));
    }
  };

  /*const handleUpgradePlan = async (newPlanId) => {
    try {
      await dispatch(changePlan(team.subscription.id, newPlanId));
      message.success(t("subscription-details.upgrade.success"));
      dispatch(fetchTeam(team.id));
    } catch (error) {
      message.error(t("subscription-details.upgrade.error"));
    }
  };*/

  const handleManageSubscription = () => {
    if (subscriptionData?.customer?.portal_url) {
      window.open(subscriptionData.customer.portal_url, "_blank");
    } else {
      message.error(t("subscription-details.portal.error"));
    }
  };

  return (
    <div className="team-subscription-tab">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {/* Subscription Plan Card */}
        <Card>
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Text type="secondary">
                {t("subscription-details.plan.label")}
              </Typography.Text>
              <Typography.Title level={4}>
                {subscriptionData?.product?.name}{" "}
                {team.subscription.status === "canceled" && (
                  <Tag color="error">
                    {t("subscription-details.label.canceled")}
                  </Tag>
                )}
              </Typography.Title>
            </Col>
            <Col>
              <Typography.Text type="secondary">
                {t("subscription-details.payment.label")}
              </Typography.Text>
              <Typography.Title level={4}>
                {subscriptionData?.product?.currency}{" "}
                {subscriptionData?.variant?.price}{" "}
                {subscriptionData?.variant?.billing_interval === "month"
                  ? t("subscription-details.payment.per_month")
                  : t("subscription-details.payment.per_year")}
              </Typography.Title>
            </Col>
            <Col>
              <Space size="small">
                {team.subscription.status === "active" && (
                  <Button type="link" onClick={handleCancelSubscription}>
                    {t("subscription-details.cancel.button")}
                  </Button>
                )}
                {team.subscription.status === "canceled" && (
                  <Button type="link" onClick={handleResumeSubscription}>
                    {t("subscription-details.resume.button")}
                  </Button>
                )}
                <Button type="primary" onClick={handleManageSubscription}>
                  {t("subscription-details.manage.button")}
                </Button>
              </Space>
            </Col>
          </Row>

          <Divider />

          {/* Usage Stats */}
          <Row gutter={16}>
            <Col span={8}>
              <Typography.Text strong>
                {t("subscription-details.usage.subscribers.label")}
              </Typography.Text>
              <Progress percent={47} showInfo={false} style={{ flex: 1 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                {mdUp && (
                  <>
                    <Typography.Text style={{ marginRight: "10px" }}>
                      1,183 (47%)
                    </Typography.Text>
                    <Typography.Text style={{ marginLeft: "10px" }}>
                      2,500
                    </Typography.Text>
                  </>
                )}
              </div>
            </Col>
            <Col span={8}>
              <Typography.Text strong>
                {t("subscription-details.usage.emails.label")}
              </Typography.Text>
              <Progress percent={12} showInfo={false} style={{ flex: 1 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                {mdUp && (
                  <>
                    <Typography.Text style={{ marginRight: "10px" }}>
                      412
                    </Typography.Text>
                    <Typography.Text style={{ marginLeft: "10px" }}>
                      4000
                    </Typography.Text>
                  </>
                )}
              </div>
            </Col>
            <Col span={8}>
              <Typography.Text strong>
                {t("subscription-details.usage.workflows.label")}
              </Typography.Text>
              <Progress percent={0} showInfo={false} style={{ flex: 1 }} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                {mdUp && (
                  <>
                    <Typography.Text style={{ marginRight: "10px" }}>
                      0
                    </Typography.Text>
                    <Typography.Text style={{ marginRight: "10px" }}>
                      10
                    </Typography.Text>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Card>

        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card className="team-subscription-card" style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ flexGrow: 1, marginBottom: 16 }}>
                  <Typography.Text type="secondary">
                    {t("subscription-details.knowledge-base.label")}
                  </Typography.Text>
                  <Row>
                    <Col
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        minHeight: "100%",
                      }}
                    >
                      <QuestionOutlined
                        style={{
                          fontSize: "4em",
                          color: "var(--primary-color)",
                        }}
                      />
                    </Col>
                    <Col xs={18}>
                      <Typography.Title level={4}>
                        {t("subscription-details.knowledge-base.title")}
                      </Typography.Title>
                      <Typography.Text>
                        {t("subscription-details.knowledge-base.text")}{" "}
                        <Link to="/knowledge-base">
                          {t("subscription-details.knowledge-base.link")}
                        </Link>
                      </Typography.Text>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
          <Col className="team-subscription-card" xs={24} md={12}>
            <Card style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div style={{ flexGrow: 1, marginBottom: 16 }}>
                  <Typography.Text type="secondary">
                    {t("subscription-details.support.label")}
                  </Typography.Text>
                  <Typography.Title level={4}>
                    {t("subscription-details.support.title")}
                  </Typography.Title>
                  <Typography.Text>
                    {t("subscription-details.support.text")}
                  </Typography.Text>
                </div>

                <div style={{ marginTop: "auto" }}>
                  <Button type="default">
                    {t("subscription-details.support.button")}
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default SubscriptionDetails;
