// Filename: routers/PagesRouter.js

import React from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "../../../core/accounts/routers/ProtectedRoute";
import PrivateLayout from "../../../core/layout/components/PrivateLayout";

import PagesList from "../components/PagesList";
import PageDetail from "../components/PageDetail";

const PagesRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PrivateLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<PagesList />} />
        <Route path="/:pageId/" element={<PageDetail />} />
      </Route>
    </Routes>
  );
};

export default PagesRouter;
