// actions/experimentsActions.js

import {
  fetchExperiments,
  fetchExperimentDetail,
  createExperiment,
  updateExperimentStatus,
  deleteExperiment,
} from "../api/experimentsApi";

// Fetch experiments with support for pagination, filtering, and search
export const getExperiments =
  (projectId, params = {}) =>
  async (dispatch) => {
    dispatch({ type: "EXPERIMENTS_LOADING" });

    try {
      const data = await fetchExperiments(projectId, params);
      dispatch({
        type: "EXPERIMENTS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "EXPERIMENTS_FAILURE",
        error: error.message,
      });
    }
  };

// Fetch details for a specific experiment
export const getExperimentDetail = (experimentId) => async (dispatch) => {
  dispatch({ type: "EXPERIMENT_DETAIL_LOADING" });

  try {
    const data = await fetchExperimentDetail(experimentId);
    dispatch({
      type: "EXPERIMENT_DETAIL_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "EXPERIMENT_DETAIL_FAILURE",
      error: error.message,
    });
  }
};

// Create a new experiment
export const createNewExperiment = (experimentData) => async (dispatch) => {
  dispatch({ type: "EXPERIMENT_CREATE_LOADING" });

  try {
    const data = await createExperiment(experimentData);
    dispatch({
      type: "EXPERIMENT_CREATE_SUCCESS",
      payload: data,
    });
    // Optionally, you can refresh the experiments list after creation
  } catch (error) {
    dispatch({
      type: "EXPERIMENT_CREATE_FAILURE",
      error: error.message,
    });
  }
};

// Update experiment status (mark as successful/unsuccessful)
export const updateExperimentStatusAction =
  (experimentId, status) => async (dispatch) => {
    dispatch({ type: "EXPERIMENT_UPDATE_STATUS_LOADING" });

    try {
      const data = await updateExperimentStatus(experimentId, status);
      dispatch({
        type: "EXPERIMENT_UPDATE_STATUS_SUCCESS",
        payload: data,
      });
      // Optionally, refresh the experiment detail or list
    } catch (error) {
      dispatch({
        type: "EXPERIMENT_UPDATE_STATUS_FAILURE",
        error: error.message,
      });
    }
  };

// Delete an experiment
export const deleteExperimentAction = (experimentId) => async (dispatch) => {
  dispatch({ type: "EXPERIMENT_DELETE_LOADING" });

  try {
    await deleteExperiment(experimentId);
    dispatch({
      type: "EXPERIMENT_DELETE_SUCCESS",
      payload: experimentId,
    });
    // Optionally, refresh the experiments list after deletion
  } catch (error) {
    dispatch({
      type: "EXPERIMENT_DELETE_FAILURE",
      error: error.message,
    });
  }
};
