import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Fetch all projects
export const fetchProjects = async (teamId) => {
  const response = await apiClient.get(`/projects/?organization=${teamId}`);
  return response.data;
};

// Fetch a single project by ID
export const fetchProjectById = async (projectId) => {
  const response = await apiClient.get(`/projects/${projectId}/`);
  return response.data;
};

// Create a new project
export const createProject = async (projectData) => {
  const response = await apiClient.post("/projects/", projectData);
  return response.data;
};

// Update a project by ID
export const updateProject = async (projectId, projectData) => {
  const response = await apiClient.put(`/projects/${projectId}/`, projectData);
  return response.data;
};

// Delete a project by ID
export const deleteProject = async (projectId) => {
  const response = await apiClient.delete(`/projects/${projectId}/`);
  return response.data;
};

// Fetch project users
export const fetchProjectUsers = async (projectId) => {
  const response = await apiClient.get(`/projects/${projectId}/users/`);
  return response.data;
};

// Create a project user
export const createProjectUser = async (projectId, userData) => {
  const response = await apiClient.post(
    `/projects/${projectId}/users/`,
    userData
  );
  return response.data;
};

// Update a project user
export const updateProjectUser = async (projectId, projectUserId, userData) => {
  const response = await apiClient.put(
    `/projects/${projectId}/users/${projectUserId}/`,
    userData
  );
  return response.data;
};

// Delete a project user
export const deleteProjectUser = async (projectId, projectUserId) => {
  const response = await apiClient.delete(
    `/projects/${projectId}/users/${projectUserId}/`
  );
  return response.data;
};
