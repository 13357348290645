import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { fetchTeam } from "../actions/teamsActions";

const SubscriptionResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSuccess = location.pathname.includes("success");

  useEffect(() => {
    if (isSuccess) {
      message.success("Subscription Successful!");
    } else {
      message.error("Subscription Cancelled.");
    }
    navigate("/teams/");
  }, [isSuccess, navigate]);

  return null;
};

export default SubscriptionResult;
