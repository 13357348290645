import { combineReducers } from "redux";
import authReducer from "./core/accounts/reducers/authReducer";
import layoutReducer from "./core/layout/reducers/layoutReducer";
import teamsReducer from "./core/teams/reducers/teamsReducer";
import notificationsReducer from "./core/notifications/reducers/notificationsReducer";

import projectsReducer from "./app/projects/reducers/projectsReducer";
import changelogsReducer from "./app/changelogs/reducers/changelogsReducer";
import changeTypesReducer from "./app/changelogs/reducers/changetypesReducer";
import pagesReducer from "./app/pages/reducers/pagesReducers";
import keywordsReducer from "./app/keywords/reducers/keywordsReducers";
import experimentsReducer from "./app/experiments/reducers/experimentsreducer";

// Combine all your reducers into one root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  layout: layoutReducer,
  teams: teamsReducer,
  notifications: notificationsReducer,

  projects: projectsReducer,
  changelogs: changelogsReducer,
  changetypes: changeTypesReducer,
  pages: pagesReducer,
  keywords: keywordsReducer,
  experiments: experimentsReducer,
});

export default rootReducer;
