// Filename: components/KeywordsList.js

import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Spin, Typography, message, Empty, Divider } from "antd";
import { getKeywords } from "../actions/keywordsActions";
import KeywordFilter from "./KeywordFilter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const KeywordsList = () => {
  const dispatch = useDispatch();
  const { keywords, loading, error } = useSelector((state) => state.keywords);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const { t } = useTranslation("keywords");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filters, setFilters] = useState({});
  const [ordering, setOrdering] = useState("-created_at");

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1); // Reset to first page when filters change
  };

  useEffect(() => {
    if (currentProject?.id) {
      const params = {
        page,
        page_size: pageSize,
        ordering,
        ...filters,
        include: "stats",
      };
      dispatch(getKeywords(currentProject.id, params));
    }
  }, [currentProject, dispatch, page, pageSize, filters, ordering]);

  useEffect(() => {
    if (error) {
      message.error(t("keywords-list.error.loading_failed"));
    }
  }, [error, t]);

  // Define the columns for the table
  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: t("keywords-list.label.keyword_text"),
        dataIndex: "keyword_text",
        key: "keyword_text",
        sorter: true,
        render: (text, record) => (
          <Link to={`/keywords/${record.id}/`}>{text}</Link>
        ),
        width: 250,
      },
      {
        title: t("keywords-list.label.pages"),
        dataIndex: "pages",
        key: "pages",
        render: (pages) =>
          pages && pages.length > 0
            ? pages.map((page, index) => (
                <span key={page.id}>
                  <Link to={`/pages/${page.id}/`}>
                    {page.title || t("keywords-list.label.no_value")}
                  </Link>
                  {index < pages.length - 1 && ", "}
                </span>
              ))
            : t("keywords-list.label.no_pages"),
        width: 300,
      },
      {
        title: t("keywords-list.label.created_at"),
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
        render: (text) => new Date(text).toLocaleString(),
        width: 180,
      },
      {
        title: t("keywords-list.label.updated_at"),
        dataIndex: "updated_at",
        key: "updated_at",
        sorter: true,
        render: (text) => new Date(text).toLocaleString(),
        width: 180,
      },
    ];

    // Add stats columns if stats are included
    const statsColumns = [
      {
        title: t("keywords-list.label.impressions"),
        dataIndex: "impressions",
        key: "impressions",
        sorter: true,
        render: (value) => value || 0,
        width: 150,
      },
      {
        title: t("keywords-list.label.clicks"),
        dataIndex: "clicks",
        key: "clicks",
        sorter: true,
        render: (value) => value || 0,
        width: 150,
      },
      {
        title: t("keywords-list.label.ctr"),
        dataIndex: "ctr",
        key: "ctr",
        sorter: true,
        render: (value) =>
          value !== undefined && value !== null
            ? `${(value * 100).toFixed(2)}%`
            : "0.00%",
        width: 150,
      },
      {
        title: t("keywords-list.label.position"),
        dataIndex: "position",
        key: "position",
        sorter: true,
        render: (value) =>
          value !== undefined && value !== null ? value.toFixed(2) : "0.00",
        width: 150,
      },
    ];

    return [...baseColumns, ...statsColumns];
  }, [t]);

  // Handle table changes like pagination and sorting
  const handleTableChange = (pagination, filtersFromTable, sorter) => {
    // Update page and page size
    setPage(pagination.current);
    setPageSize(pagination.pageSize);

    // Update ordering based on sorter
    if (sorter.order) {
      const orderPrefix = sorter.order === "ascend" ? "" : "-";
      setOrdering(`${orderPrefix}${sorter.field}`);
    } else {
      setOrdering("-created_at"); // Default ordering
    }
  };

  return (
    <div>
      <Typography.Title level={2}>{t("keywords-list.title")}</Typography.Title>
      <KeywordFilter onFilterChange={handleFilterChange} />
      <Divider />
      {/* Table with keywords */}
      {keywords.results && keywords.results.length > 0 ? (
        <Table
          columns={columns}
          dataSource={keywords.results}
          rowKey="id"
          loading={loading}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: keywords.count,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t(
                "keywords-list.pagination.showing"
              )} ${total} ${t("keywords-list.pagination.items")}`,
          }}
          onChange={handleTableChange}
          locale={{
            emptyText: <Empty description={t("keywords-list.no_keywords")} />,
          }}
          bordered
        />
      ) : (
        <Empty description={t("keywords-list.no_keywords")} />
      )}
    </div>
  );
};

export default KeywordsList;
