// reducers/experimentsReducers.js

const initialState = {
  experiments: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  experimentDetail: null,
  loading: false,
  error: null,
  loadingDetail: false,
  errorDetail: null,
};

export default function experimentsReducer(state = initialState, action) {
  switch (action.type) {
    case "EXPERIMENTS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "EXPERIMENTS_SUCCESS":
      return {
        ...state,
        loading: false,
        experiments: action.payload,
      };
    case "EXPERIMENTS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case "EXPERIMENT_DETAIL_LOADING":
      return {
        ...state,
        loadingDetail: true,
        errorDetail: null,
      };
    case "EXPERIMENT_DETAIL_SUCCESS":
      return {
        ...state,
        loadingDetail: false,
        experimentDetail: action.payload,
      };
    case "EXPERIMENT_DETAIL_FAILURE":
      return {
        ...state,
        loadingDetail: false,
        errorDetail: action.error,
      };
    case "EXPERIMENT_CREATE_LOADING":
    case "EXPERIMENT_UPDATE_STATUS_LOADING":
    case "EXPERIMENT_DELETE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "EXPERIMENT_CREATE_SUCCESS":
    case "EXPERIMENT_UPDATE_STATUS_SUCCESS":
    case "EXPERIMENT_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
      };
    case "EXPERIMENT_CREATE_FAILURE":
    case "EXPERIMENT_UPDATE_STATUS_FAILURE":
    case "EXPERIMENT_DELETE_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
