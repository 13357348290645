// Filename: components/KeywordFilter.js

import React, { useEffect, useState, useCallback } from "react";
import { Form, Input, Select, DatePicker, message, Segmented } from "antd";
import { useTranslation } from "react-i18next";
import { fetchProjectUsers } from "../api/keywordsApi";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";

const { RangePicker } = DatePicker;

const KeywordFilter = ({ onFilterChange }) => {
  const { t } = useTranslation("keywords");
  const currentProject = useSelector((state) => state.projects.currentProject);

  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [form] = Form.useForm();

  // Debounced filter change handler to limit API calls
  const debouncedFilterChange = useCallback(
    debounce((newFilters) => {
      onFilterChange(newFilters);
    }, 500),
    [onFilterChange]
  );

  useEffect(() => {
    if (currentProject?.id) {
      // Fetch Project Users
      setLoadingUsers(true);
      fetchProjectUsers(currentProject.id)
        .then((data) => {
          if (Array.isArray(data)) {
            setUsers(data);
          } else {
            console.error("Unexpected data format for users:", data);
            setUsers([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching project users:", error);
          message.error(t("keywords-filter.error.fetch_project_users"));
          setUsers([]); // Set to empty array on error
        })
        .finally(() => {
          setLoadingUsers(false);
        });
    } else {
      // Reset filters if no project is selected
      setUsers([]);
      form.resetFields();
    }
  }, [currentProject, t, form]);

  const handleValuesChange = (changedValues, allValues) => {
    const { date_range, ...rest } = allValues;
    const newFilters = { ...rest };

    // Handle RangePicker date ranges
    if (date_range && date_range.length === 2) {
      newFilters.created_from = date_range[0].format("YYYY-MM-DD");
      newFilters.created_to = date_range[1].format("YYYY-MM-DD");
    }

    debouncedFilterChange(newFilters);
  };

  return (
    <Form
      form={form}
      layout="inline"
      onValuesChange={handleValuesChange}
      initialValues={{
        ordering: "-created_at",
      }}
    >
      {/* Search Input */}
      <Form.Item name="search">
        <Input.Search
          placeholder={t("keywords-filter.placeholder.search")}
          allowClear
          style={{ width: 200 }}
          loading={loadingUsers}
        />
      </Form.Item>

      {/* RangePicker for Custom Date Range */}
      <Form.Item name="date_range">
        <RangePicker disabled={loadingUsers} />
      </Form.Item>

      {/* User Select */}
      <Form.Item name="user">
        <Select
          placeholder={t("keywords-filter.filter.user")}
          style={{ width: 200 }}
          allowClear
          loading={loadingUsers}
          disabled={loadingUsers || users.length === 0}
        >
          {users.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.first_name} {user.last_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* Segmented Control for Preset Date Ranges */}
      <Form.Item
        name="stats_date_range"
        label={t("keywords-filter.filter.stats.label")}
      >
        <Segmented
          options={[
            {
              label: t("keywords-filter.filter.stats.last_week"),
              value: "last_week",
            },
            {
              label: t("keywords-filter.filter.stats.last_month"),
              value: "last_month",
            },
            {
              label: t("keywords-filter.filter.stats.last_year"),
              value: "last_year",
            },
          ]}
          allowClear
        />
      </Form.Item>
    </Form>
  );
};

export default KeywordFilter;
