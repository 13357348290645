import React from "react";
import { Table, Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const UsersTab = ({ users, onPermissionChange }) => {
  const { t } = useTranslation("projects");

  const columns = [
    {
      title: t("projects-users.columns.name"),
      dataIndex: "first_name",
      key: "first_name",
      render: (_, record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: t("projects-users.columns.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("projects-users.columns.permission"),
      key: "permission",
      render: (_, record) => {
        return (
          <Select
            value={record.permission || undefined}
            onChange={(value) => onPermissionChange(record.id, value)}
            style={{ width: 120 }}
            placeholder={t("projects-users.permission.none")}
          >
            <Option value="none">{t("projects-users.permission.none")}</Option>
            <Option value="read">{t("projects-users.permission.read")}</Option>
            <Option value="write">
              {t("projects-users.permission.write")}
            </Option>
          </Select>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={users}
      rowKey="id"
      pagination={false}
      scroll={{ x: "max-content" }}
    />
  );
};

export default UsersTab;
