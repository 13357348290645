import React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthRouter from "./modules/core/accounts/routers/AuthRouter";
import LayoutRouter from "./modules/core/layout/routers/LayoutRouter";
import DashboardRouter from "./modules/dashboard/routers/DashboardRouter";
import TeamsRouter from "./modules/core/teams/routers/TeamsRouter";
import NotificationsRouter from "./modules/core/notifications/routers/NotificationsRouter";
import NotFound from "./modules/page/components/NotFound";

import ProjectsRouter from "./modules/app/projects/routers/ProjectsRouter";
import ChangelogsRouter from "./modules/app/changelogs/routers/ChangelogsRouter";
import PagesRouter from "./modules/app/pages/routers/PagesRouter";
import KeywordsRouter from "./modules/app/keywords/routers/KeywordsRouter";
import ExperimentsRouter from "./modules/app/experiments/routers/ExperimentsRouter";

const AppRouter = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            auth.isAuthenticated ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/account/login" />
            )
          }
        />
        {/* Account specific routes */}
        <Route path="/account/*" element={<AuthRouter />} />
        {/* Layout routes */}
        <Route path="/layout/*" element={<LayoutRouter />} />
        {/* Dashboard or other routes */}
        <Route path="/dashboard/*" element={<DashboardRouter />} />
        {/* Teams routes */}
        <Route path="/teams/*" element={<TeamsRouter />} />
        {/* Notifications routes */}
        <Route path="/notifications/*" element={<NotificationsRouter />} />
        {/* Projects routes */}
        <Route path="/projects/*" element={<ProjectsRouter />} />
        {/* Changelogs routes */}
        <Route path="/changelogs/*" element={<ChangelogsRouter />} />
        {/* Pages routes */}
        <Route path="/pages/*" element={<PagesRouter />} />
        {/* Keywords routes */}
        <Route path="/keywords/*" element={<KeywordsRouter />} />
        {/* Experiments routes */}
        <Route path="/experiments/*" element={<ExperimentsRouter />} />
        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
