// Filename: components/PageFilter.js

import React, { useEffect, useState, useCallback } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Switch,
  message,
  Segmented,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { fetchProjectUsers } from "../api/pagesApi"; // Removed fetchPages as it's not used here
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";

const { RangePicker } = DatePicker;

const PageFilter = ({ onFilterChange }) => {
  const { t } = useTranslation("pages");
  const currentProject = useSelector((state) => state.projects.currentProject);

  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [form] = Form.useForm();

  // Debounced filter change handler to limit API calls
  const debouncedFilterChange = useCallback(
    debounce((newFilters) => {
      onFilterChange(newFilters);
    }, 500), // 500ms debounce delay
    [onFilterChange]
  );

  useEffect(() => {
    if (currentProject?.id) {
      // Fetch Project Users
      setLoadingUsers(true);
      fetchProjectUsers(currentProject.id)
        .then((data) => {
          if (Array.isArray(data)) {
            setUsers(data);
          } else {
            console.error("Unexpected data format for users:", data);
            setUsers([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching project users:", error);
          message.error(t("pages-filter.error.fetch_project_users"));
          setUsers([]);
        })
        .finally(() => {
          setLoadingUsers(false);
        });
    } else {
      // Reset filters if no project is selected
      setUsers([]);
      form.resetFields();
    }
  }, [currentProject, t, form]);

  const handleValuesChange = (changedValues, allValues) => {
    const { stats_date_range, date_range, exclude_initial, ...rest } =
      allValues;
    const newFilters = { ...rest };

    // Handle preset date ranges
    if (stats_date_range) {
      newFilters.stats_date_range = stats_date_range;
    }

    // Handle RangePicker date ranges
    if (date_range && date_range.length === 2) {
      newFilters.created_from = date_range[0].format("YYYY-MM-DD");
      newFilters.created_to = date_range[1].format("YYYY-MM-DD");
    }

    // Handle exclude_initial switch
    if (exclude_initial) {
      newFilters.is_initial = false;
    } else {
      delete newFilters.is_initial; // Include all logs if unchecked
    }

    debouncedFilterChange(newFilters);
  };

  return (
    <Form
      form={form}
      layout="inline"
      onValuesChange={handleValuesChange}
      initialValues={{
        ordering: "-created_at",
        exclude_initial: false, // Default: show all pages
      }}
    >
      {/* Search Input */}
      <Form.Item name="search">
        <Input.Search
          placeholder={t("pages-filter.placeholder.search")}
          allowClear
          style={{ width: 200 }}
        />
      </Form.Item>

      {/* RangePicker for Custom Date Range */}
      <Form.Item name="date_range">
        <RangePicker disabled={loadingUsers} />
      </Form.Item>

      {/* User Select */}
      <Form.Item name="user">
        <Select
          placeholder={t("pages-filter.filter.user")}
          style={{ width: 200 }}
          allowClear
          loading={loadingUsers}
          disabled={loadingUsers || users.length === 0}
        >
          {users.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.first_name} {user.last_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* Segmented Control for Preset Date Ranges */}
      <Form.Item
        name="stats_date_range"
        label={t("pages-filter.filter.stats.label")}
      >
        <Segmented
          options={[
            {
              label: t("pages-filter.filter.stats.last_week"),
              value: "last_week",
            },
            {
              label: t("pages-filter.filter.stats.last_month"),
              value: "last_month",
            },
            {
              label: t("pages-filter.filter.stats.last_year"),
              value: "last_year",
            },
          ]}
          allowClear
        />
      </Form.Item>
    </Form>
  );
};

export default PageFilter;
