import { fetchChangelogs } from "../api/changelogsApi";

// Fetch changelogs for a project with support for pagination, filtering, and search
export const getChangelogs =
  (projectId, params = {}) =>
  async (dispatch) => {
    dispatch({ type: "CHANGELOGS_LOADING" });

    try {
      const data = await fetchChangelogs(projectId, params);
      dispatch({
        type: "CHANGELOGS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "CHANGELOGS_FAILURE",
        error: error.message,
      });
    }
  };
