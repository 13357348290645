const savedProjectId = localStorage.getItem("selectedProjectId") || null;

const initialState = {
  projects: [],
  project: null,
  currentProject: savedProjectId ? { id: parseInt(savedProjectId, 10) } : null,
  loading: false,
  error: null,
  gscProperties: [],
  gscPropertiesLoading: false,
  gscPropertiesError: null,
};

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case "PROJECTS_LOADING":
    case "PROJECT_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "PROJECTS_SUCCESS":
      return {
        ...state,
        loading: false,
        projects: action.payload,
      };

    case "PROJECT_SUCCESS":
      return {
        ...state,
        loading: false,
        project: action.payload,
      };

    case "PROJECTS_FAILURE":
    case "PROJECT_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case "SET_CURRENT_PROJECT":
      if (action?.payload?.id) {
        localStorage.setItem("selectedProjectId", action.payload.id);
      } else {
        localStorage.removeItem("selectedProjectId");
      }

      return {
        ...state,
        currentProject: action.payload,
      };

    case "CREATE_PROJECT_SUCCESS":
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };

    case "UPDATE_PROJECT_SUCCESS":
      return {
        ...state,
        projects: state.projects.map((proj) =>
          proj.id === action.payload.id ? action.payload : proj
        ),
      };

    case "DELETE_PROJECT_SUCCESS":
      return {
        ...state,
        projects: state.projects.filter((proj) => proj.id !== action.payload),
      };

    case "GSC_PROPERTIES_LOADING":
      return {
        ...state,
        gscPropertiesLoading: true,
        gscPropertiesError: null,
      };
    case "GSC_PROPERTIES_SUCCESS":
      return {
        ...state,
        gscPropertiesLoading: false,
        gscProperties: action.payload,
      };
    case "GSC_PROPERTIES_FAILURE":
      return {
        ...state,
        gscPropertiesLoading: false,
        gscPropertiesError: action.error,
      };
    // Handle other GSC-related actions if necessary

    case "PROJECT_DATA_FETCH_START":
      return {
        ...state,
        // Optionally, update state to reflect data fetch started
      };
    case "PROJECT_DATA_FETCH_SUCCESS":
      return {
        ...state,
        // Optionally, update state to reflect data fetch success
      };
    case "PROJECT_DATA_FETCH_FAILURE":
      return {
        ...state,
        // Optionally, update state to reflect data fetch failure
      };

    default:
      return state;
  }
}
