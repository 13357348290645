import React from "react";
import NoSubscription from "./NoSubscription";
import SubscriptionDetails from "./SubscriptionDetails";

const TeamSubscriptionTab = ({ team }) => {
  if (
    team.subscription?.status === "active" ||
    team.subscription?.status === "canceled"
  ) {
    return <SubscriptionDetails team={team} />;
  } else {
    return <NoSubscription team={team} />;
  }
};

export default TeamSubscriptionTab;
