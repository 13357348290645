import {
  TOGGLE_THEME,
  SET_THEME,
  SET_THEME_SETTINGS,
  SET_USER_TOGGLED,
} from "../actions/layoutActions";

// Load initial state from localStorage
const initialThemeMode = localStorage.getItem("themeMode") || "system";
const initialCollapsed = JSON.parse(localStorage.getItem("collapsed")) || false;

const initialState = {
  themeMode: initialThemeMode,
  themeSettings: {
    primaryColor: "#0652DD",
    secondaryColor: "#ff4d4f",
    textColor: "#333333",
    fontSizeBase: "14px",
    backgroundColor: "#ffffff",
  },
  collapsed: initialCollapsed, // Initialize the collapsed state
  userToggled: false,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      const newThemeMode = state.themeMode === "light" ? "dark" : "light";
      localStorage.setItem("themeMode", newThemeMode); // Persist theme mode
      return {
        ...state,
        themeMode: newThemeMode,
      };
    case SET_THEME:
      console.log("Payload: ", action.payload.themeMode);
      if (action.payload.themeMode === "system") {
        localStorage.removeItem("themeMode");
        return {
          ...state,
          userToggled: false,
        };
      } else {
        localStorage.setItem("themeMode", action.payload.themeMode);
        return {
          ...state,
          themeMode: action.payload.themeMode,
          userToggled: true,
        };
      }
    case SET_THEME_SETTINGS:
      return {
        ...state,
        themeSettings: { ...state.themeSettings, ...action.payload },
      };
    case SET_USER_TOGGLED:
      return {
        ...state,
        userToggled: action.payload,
      };
    default:
      return state;
  }
};

export default layoutReducer;
