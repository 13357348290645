// components/ExperimentTypeSelector.js

import React from "react";
import { Modal, Button, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
  ExperimentOutlined,
  GroupOutlined,
  SplitCellsOutlined,
  SwapOutlined,
} from "@ant-design/icons";

const ExperimentTypeSelector = ({ visible, onSelect, onCancel }) => {
  const { t } = useTranslation("experiments");

  const experimentTypes = [
    {
      key: "single",
      icon: (
        <ExperimentOutlined style={{ fontSize: "24px", color: "#ffffff" }} />
      ),
      title: t("experiment-type.single.title"),
      description: t("experiment-type.single.description"),
      color: "#ff4d4f",
    },
    {
      key: "group",
      icon: <GroupOutlined style={{ fontSize: "24px", color: "#ffffff" }} />,
      title: t("experiment-type.group.title"),
      description: t("experiment-type.group.description"),
      color: "#1890ff",
    },
    {
      key: "split",
      icon: (
        <SplitCellsOutlined style={{ fontSize: "24px", color: "#ffffff" }} />
      ),
      title: t("experiment-type.split.title"),
      description: t("experiment-type.split.description"),
      color: "#52c41a",
    },
    {
      key: "switch",
      icon: <SwapOutlined style={{ fontSize: "24px", color: "#ffffff" }} />,
      title: t("experiment-type.switch.title"),
      description: t("experiment-type.switch.description"),
      color: "#faad14",
    },
  ];

  return (
    <Modal
      title={t("experiment-type.modal.title")}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={1000}
    >
      <Row gutter={[16, 16]}>
        {experimentTypes.map((type) => (
          <Col xs={24} sm={12} key={type.key}>
            <Button
              block
              onClick={() => onSelect(type.key)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                padding: "20px",
                height: "auto",
                backgroundColor: type.color,
                borderColor: type.color,
                color: "#ffffff",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {type.icon}
              <div style={{ marginLeft: "20px", textAlign: "left" }}>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  {type.title}
                </Typography.Title>
                <Typography.Text>{type.description}</Typography.Text>
              </div>
            </Button>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default ExperimentTypeSelector;
