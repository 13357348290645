// Filename: components/KeywordDetail.js

import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Spin,
  message,
  Empty,
  Divider,
  Segmented,
  Row,
  Col,
  Table, // Import Table from antd
} from "antd";
import { getKeywordDetail, getKeywordPages } from "../actions/keywordsActions"; // Import getKeywordPages
import KeywordPageFilter from "./KeywordPageFilter"; // Import the KeywordPageFilter component
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { Line } from "@ant-design/charts"; // Import Line chart from Ant Design Charts
import { fetchStatistics } from "../api/statsApi"; // Import the new API function

const KeywordDetail = () => {
  const dispatch = useDispatch();
  const {
    keywordDetail,
    loadingDetail,
    errorDetail,
    keywordPages,
    loadingKeywordPages,
    errorKeywordPages,
  } = useSelector((state) => state.keywords); // Destructure keywordPages and related states
  const currentProject = useSelector((state) => state.projects.currentProject);
  const { t } = useTranslation("keywords");
  const { keywordId } = useParams();

  // State for charts
  const [chartPeriod, setChartPeriod] = useState("3 Months"); // Default period
  const [chartAggregation, setChartAggregation] = useState("Day"); // Default aggregation
  const [chartData, setChartData] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);

  // State for pages
  const [pagePage, setPagePage] = useState(1);
  const [pagePageSize, setPagePageSize] = useState(10);
  const [pageOrdering, setPageOrdering] = useState("-created_at");
  const [pageFilters, setPageFilters] = useState({});
  const [pageStatsDateRange, setPageStatsDateRange] = useState("last_month");

  useEffect(() => {
    if (currentProject?.id && keywordId) {
      dispatch(getKeywordDetail(keywordId));
    }
  }, [currentProject, dispatch, keywordId]);

  // Fetch chart data when chart-related state changes
  useEffect(() => {
    if (currentProject?.id && keywordId) {
      fetchChartData(); // Fetch chart data on chart-related state change
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, keywordId, chartPeriod, chartAggregation]);

  // Fetch pages when relevant state changes
  useEffect(() => {
    if (currentProject?.id && keywordId) {
      fetchPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentProject,
    keywordId,
    pagePage,
    pagePageSize,
    pageOrdering,
    pageFilters,
    pageStatsDateRange,
  ]);

  useEffect(() => {
    if (errorDetail) {
      message.error(t("keyword-detail.error.loading_failed"));
    }
    if (errorKeywordPages) {
      message.error(t("keyword-detail.pages.error.loading_failed"));
    }
  }, [errorDetail, errorKeywordPages, t]);

  const fetchPages = () => {
    const params = {
      page: pagePage,
      page_size: pagePageSize,
      ordering: pageOrdering,
      include: "stats",
      stats_date_range: pageStatsDateRange,
      ...pageFilters,
    };
    dispatch(getKeywordPages(currentProject.id, keywordId, params));
  };

  const handlePageFilterChange = (newFilters) => {
    setPageFilters(newFilters);
    setPagePage(1); // Reset to first page when filters change
    if (newFilters.stats_date_range) {
      setPageStatsDateRange(newFilters.stats_date_range);
    }
  };

  const handlePageTableChange = (pagination, filtersFromTable, sorter) => {
    setPagePage(pagination.current);
    setPagePageSize(pagination.pageSize);

    if (sorter.order) {
      const orderPrefix = sorter.order === "ascend" ? "" : "-";
      setPageOrdering(`${orderPrefix}${sorter.field}`);
    } else {
      setPageOrdering("-created_at");
    }
  };

  // Define columns for pages table
  const pageColumns = useMemo(
    () => [
      {
        title: t("keyword-detail.pages.label.title"),
        dataIndex: "title",
        key: "title",
        sorter: true,
        render: (text, record) => (
          <Link to={`/pages/${record.id}/`}>
            {text || t("keyword-detail.pages.label.no_value")}
          </Link>
        ),
        width: 250,
      },
      {
        title: t("keyword-detail.pages.label.url"),
        dataIndex: "url",
        key: "url",
        sorter: true,
        render: (text) => (
          <a href={text} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        ),
        width: 300,
      },
      {
        title: t("keyword-detail.pages.label.impressions"),
        dataIndex: "impressions",
        key: "impressions",
        sorter: true,
        render: (value) => value || 0,
        width: 150,
      },
      {
        title: t("keyword-detail.pages.label.clicks"),
        dataIndex: "clicks",
        key: "clicks",
        sorter: true,
        render: (value) => value || 0,
        width: 150,
      },
      {
        title: t("keyword-detail.pages.label.ctr"),
        dataIndex: "ctr",
        key: "ctr",
        sorter: true,
        render: (value) =>
          value !== undefined && value !== null
            ? `${(value * 100).toFixed(2)}%`
            : "0.00%",
        width: 150,
      },
      {
        title: t("keyword-detail.pages.label.position"),
        dataIndex: "position",
        key: "position",
        sorter: true,
        render: (value) =>
          value !== undefined && value !== null ? value.toFixed(2) : "0.00",
        width: 150,
      },
    ],
    [t]
  );

  // Define the mapping for chart periods to date ranges
  const periodMapping = {
    "1 Month": {
      label: "1 Month",
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      endDate: new Date(),
    },
    "3 Months": {
      label: "3 Months",
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
      endDate: new Date(),
    },
    "6 Months": {
      label: "6 Months",
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
      endDate: new Date(),
    },
    "This Year": {
      label: "This Year",
      startDate: new Date(new Date().getFullYear(), 0, 1), // January 1st of current year
      endDate: new Date(),
    },
    "12 Months": {
      label: "12 Months",
      startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      endDate: new Date(),
    },
    "All Time": {
      label: "All Time",
      startDate: null, // Will fetch all records
      endDate: new Date(),
    },
  };

  const fetchChartData = async () => {
    setLoadingChart(true);
    try {
      // Determine start and end dates based on selected period
      const selectedPeriod = periodMapping[chartPeriod];
      let params = {
        level: "keyword",
        data_source: "gsc",
        aggregation: chartAggregation.toLowerCase(), // 'day', 'week', 'month'
        keyword_id: keywordId,
      };

      if (selectedPeriod.startDate && selectedPeriod.endDate) {
        params.date_range = "specific";
        // Format dates as YYYY-MM-DD
        params.start_date = selectedPeriod.startDate
          .toISOString()
          .split("T")[0];
        params.end_date = selectedPeriod.endDate.toISOString().split("T")[0];
      } else {
        params.date_range = "all";
      }

      // Use the fetchProjectStatistics API function
      const data = await fetchStatistics(currentProject.id, params);

      // Transform data for the charts
      const combinedMetrics = {};

      data.forEach((source) => {
        source.statistics.forEach((entry) => {
          const period = entry.period;
          if (!combinedMetrics[period]) {
            combinedMetrics[period] = {
              period: period,
              impressions: 0,
              clicks: 0,
              ctr: 0,
              position: 0,
            };
          }
          combinedMetrics[period].impressions += entry.impressions || 0;
          combinedMetrics[period].clicks += entry.clicks || 0;
          combinedMetrics[period].ctr += entry.ctr || 0;
          combinedMetrics[period].position += entry.position || 0;
        });
      });

      // Convert combinedMetrics to an array and calculate average CTR and Position
      const chartDataArray = Object.values(combinedMetrics).map((item) => ({
        period: item.period,
        impressions: item.impressions,
        clicks: item.clicks,
        ctr: item.impressions > 0 ? item.clicks / item.impressions : 0,
        position: item.impressions > 0 ? item.position / item.impressions : 0,
      }));

      // Sort the chart data by period
      chartDataArray.sort((a, b) => new Date(a.period) - new Date(b.period));

      setChartData(chartDataArray);
    } catch (error) {
      console.error(error);
      message.error(t("error.loading_failed"));
    } finally {
      setLoadingChart(false);
    }
  };

  const handlePeriodChange = (value) => {
    setChartPeriod(value);
  };

  const handleAggregationChange = (value) => {
    setChartAggregation(value);
  };

  // Define chart configurations for each metric
  const chartConfigImpressions = useMemo(() => {
    return {
      data: chartData,
      xField: "period",
      yField: "impressions",
      color: "#1976D2",
      xAxis: {
        type: "time",
        mask: "YYYY-MM-DD",
      },
      yAxis: {
        title: {
          text: t("keyword-detail.statistics.impressions"),
        },
      },
      tooltip: {
        shared: true,
        showMarkers: false,
      },
      legend: {
        position: "top-left",
      },
    };
  }, [chartData, t]);

  const chartConfigClicks = useMemo(() => {
    return {
      data: chartData,
      xField: "period",
      yField: "clicks",
      color: "#FF5722",
      xAxis: {
        type: "time",
        mask: "YYYY-MM-DD",
      },
      yAxis: {
        title: {
          text: t("keyword-detail.statistics.clicks"),
        },
      },
      tooltip: {
        shared: true,
        showMarkers: false,
      },
      legend: {
        position: "top-left",
      },
    };
  }, [chartData, t]);

  const chartConfigCTR = useMemo(() => {
    return {
      data: chartData,
      xField: "period",
      yField: "ctr",
      color: "#4CAF50",
      xAxis: {
        type: "time",
        mask: "YYYY-MM-DD",
      },
      yAxis: {
        title: {
          text: t("keyword-detail.statistics.ctr"),
        },
        min: 0,
        max: 1,
        label: {
          formatter: (v) => `${(v * 100).toFixed(2)}%`,
        },
      },
      tooltip: {
        formatter: (datum) => ({
          name: t("keyword-detail.statistics.ctr"),
          value: `${(datum.ctr * 100).toFixed(2)}%`,
        }),
      },
      legend: {
        position: "top-left",
      },
    };
  }, [chartData, t]);

  const chartConfigPosition = useMemo(() => {
    return {
      data: chartData,
      xField: "period",
      yField: "position",
      color: "#9C27B0",
      xAxis: {
        type: "time",
        mask: "YYYY-MM-DD",
      },
      yAxis: {
        title: {
          text: t("keyword-detail.statistics.position"),
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        showMarkers: false,
      },
      legend: {
        position: "top-left",
      },
    };
  }, [chartData, t]);

  return (
    <div>
      {loadingDetail ? (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {keywordDetail ? (
            <>
              <Typography.Title level={2}>
                {t("keyword-detail.title")}
              </Typography.Title>
              <Typography.Paragraph>
                <strong>{t("keyword-detail.label.keyword_text")}:</strong>{" "}
                {keywordDetail.keyword_text}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <strong>{t("keyword-detail.label.created_at")}:</strong>{" "}
                {new Date(keywordDetail.created_at).toLocaleString()}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <strong>{t("keyword-detail.label.updated_at")}:</strong>{" "}
                {new Date(keywordDetail.updated_at).toLocaleString()}
              </Typography.Paragraph>
              <Divider />

              {/* Chart Controls */}
              <Typography.Title level={3}>
                {t("keyword-detail.statistics.title")}
              </Typography.Title>
              <Row gutter={[16, 16]}>
                <Col>
                  <Typography.Text>
                    {t("keyword-detail.statistics.label.period")}:
                  </Typography.Text>{" "}
                  <Segmented
                    options={Object.keys(periodMapping)}
                    value={chartPeriod}
                    onChange={handlePeriodChange}
                  />
                </Col>
                <Col>
                  <Typography.Text>
                    {t("keyword-detail.statistics.label.aggregation")}:
                  </Typography.Text>{" "}
                  <Segmented
                    options={["Day", "Week", "Month"]}
                    value={chartAggregation}
                    onChange={handleAggregationChange}
                  />
                </Col>
              </Row>
              <Divider />

              {/* Line Charts */}
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                  <Typography.Title level={5}>
                    {t("keyword-detail.statistics.impressions")}
                  </Typography.Title>
                  {loadingChart ? (
                    <Spin />
                  ) : chartData.length > 0 ? (
                    <Line {...chartConfigImpressions} />
                  ) : (
                    <Empty
                      description={t("keyword-detail.statistics.no_data")}
                    />
                  )}
                </Col>
                <Col xs={24} lg={12}>
                  <Typography.Title level={5}>
                    {t("keyword-detail.statistics.clicks")}
                  </Typography.Title>
                  {loadingChart ? (
                    <Spin />
                  ) : chartData.length > 0 ? (
                    <Line {...chartConfigClicks} />
                  ) : (
                    <Empty
                      description={t("keyword-detail.statistics.no_data")}
                    />
                  )}
                </Col>
                <Col xs={24} lg={12}>
                  <Typography.Title level={5}>
                    {t("keyword-detail.statistics.ctr")}
                  </Typography.Title>
                  {loadingChart ? (
                    <Spin />
                  ) : chartData.length > 0 ? (
                    <Line {...chartConfigCTR} />
                  ) : (
                    <Empty
                      description={t("keyword-detail.statistics.no_data")}
                    />
                  )}
                </Col>
                <Col xs={24} lg={12}>
                  <Typography.Title level={5}>
                    {t("keyword-detail.statistics.position")}
                  </Typography.Title>
                  {loadingChart ? (
                    <Spin />
                  ) : chartData.length > 0 ? (
                    <Line {...chartConfigPosition} />
                  ) : (
                    <Empty
                      description={t("keyword-detail.statistics.no_data")}
                    />
                  )}
                </Col>
              </Row>
              <Divider />

              {/* Pages Section */}
              <Typography.Title level={3}>
                {t("keyword-detail.pages.title")}
              </Typography.Title>
              <KeywordPageFilter onFilterChange={handlePageFilterChange} />
              <Divider />
              {keywordPages?.results && keywordPages.results.length > 0 ? (
                <Table
                  columns={pageColumns}
                  dataSource={keywordPages.results}
                  rowKey="id"
                  pagination={{
                    current: pagePage,
                    pageSize: pagePageSize,
                    total: keywordPages.count,
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t(
                        "keyword-detail.pages.pagination.showing"
                      )} ${total} ${t(
                        "keyword-detail.pages.pagination.items"
                      )}`,
                  }}
                  onChange={handlePageTableChange}
                  bordered
                />
              ) : (
                <Empty description={t("keyword-detail.pages.no_pages")} />
              )}
            </>
          ) : (
            <Empty description={t("keyword-detail.no_keyword")} />
          )}
        </>
      )}
    </div>
  );
};

export default KeywordDetail;
