const initialState = {
  changeTypes: [],
  loading: false,
  error: null,
};

const changeTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Fetch ChangeTypes
    case "GET_CHANGE_TYPES_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_CHANGE_TYPES_SUCCESS":
      return {
        ...state,
        loading: false,
        changeTypes: action.payload,
      };
    case "GET_CHANGE_TYPES_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // Add ChangeType
    case "ADD_CHANGE_TYPE_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "ADD_CHANGE_TYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        changeTypes: [...state.changeTypes, action.payload],
      };
    case "ADD_CHANGE_TYPE_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // Edit ChangeType
    case "EDIT_CHANGE_TYPE_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "EDIT_CHANGE_TYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        changeTypes: state.changeTypes.map((ct) =>
          ct.id === action.payload.id ? action.payload : ct
        ),
      };
    case "EDIT_CHANGE_TYPE_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    // Remove ChangeType
    case "REMOVE_CHANGE_TYPE_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "REMOVE_CHANGE_TYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        changeTypes: state.changeTypes.filter((ct) => ct.id !== action.payload),
      };
    case "REMOVE_CHANGE_TYPE_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default changeTypesReducer;
