import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Typography, message, Empty, Button, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useResponsive } from "../../../core/layout/hooks/useResponsive";
import { getProjects, setCurrentProject } from "../actions/projectsActions";
import AddEditProjectModal from "./AddEditProjectModal/AddEditProjectModal";
import ProjectAvatar from "./ProjectAvatar";

const { Option } = Select;

const ProjectDropdown = ({ collapsed }) => {
  const { t } = useTranslation("projects");
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const currentTeam = useSelector((state) => state.teams.currentTeam);
  const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);

  useEffect(() => {
    if (currentTeam?.id) {
      dispatch(getProjects(currentTeam.id));
    }
  }, [currentTeam, dispatch]);

  useEffect(() => {
    if (projects.length > 0) {
      if (currentProject) {
        const savedProject = projects.find(
          (project) => project.id === currentProject.id
        );
        if (savedProject) {
          dispatch(setCurrentProject(savedProject));
        } else {
          dispatch(setCurrentProject(projects[0]));
        }
      } else if (!currentProject) {
        dispatch(setCurrentProject(projects[0]));
      }
    } else {
      dispatch(setCurrentProject(null));
    }
  }, [currentTeam, currentProject, projects, dispatch]);

  const handleProjectChange = (projectId) => {
    const selectedProject = projects.find(
      (project) => project.id === projectId
    );
    if (selectedProject) {
      dispatch(setCurrentProject(selectedProject));
      message.success(
        t("projects-dropdown.success", { projectName: selectedProject.name })
      );
    }
  };

  return (
    <>
      <Select
        style={{
          width: "100%",
          height: "48px",
          maxWidth: "100%",
          position: "relative",
          zIndex: 1000,
        }}
        value={currentProject?.name ? currentProject?.id : null}
        onChange={handleProjectChange}
        popupMatchSelectWidth={false}
        getPopupContainer={(trigger) => trigger.parentNode}
        optionLabelProp="label"
        labelInValue={false}
        placeholder={t("projects-dropdown.placeholder")}
      >
        {projects.length === 0 ? (
          <Option disabled key="no-projects">
            <Empty
              description={<span>{t("projects-dropdown.no-projects")}</span>}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsAddEditModalVisible(true)}
              >
                {t("projects-dropdown.button.create")}
              </Button>
            </Empty>
          </Option>
        ) : (
          projects.map((project) => (
            <Option
              key={project.id}
              value={project.id}
              label={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <ProjectAvatar
                    image={project.favicon}
                    name={project.name}
                    size={24}
                  />
                  {!collapsed && (
                    <Typography.Text strong style={{ marginLeft: "8px" }}>
                      {project.name}
                    </Typography.Text>
                  )}
                </div>
              }
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ProjectAvatar
                  image={project.favicon}
                  name={project.name}
                  size={32}
                />
                <Typography.Text style={{ marginLeft: "8px" }}>
                  {project.name}
                </Typography.Text>
              </div>
            </Option>
          ))
        )}
      </Select>

      {/* Modal to add a new project */}
      <AddEditProjectModal
        isOpen={isAddEditModalVisible}
        onClose={() => setIsAddEditModalVisible(false)}
      />
    </>
  );
};

export default ProjectDropdown;
