import "./App.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./modules/core/accounts/actions/authActions";
import { LayoutContextProvider } from "./modules/core/layout/context/LayoutContext";
import AppRouter from "./Router";
import { Spin } from "antd";

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  if (auth.loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <LayoutContextProvider>
      <AppRouter />
    </LayoutContextProvider>
  );
}

export default App;
