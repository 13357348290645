import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Button,
  Tabs,
  Row,
  Col,
  Dropdown,
  Menu,
  Typography,
  Spin,
  message,
  Modal, // Import Modal
} from "antd";
import { EditOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import {
  fetchTeam,
  removeTeam,
  fetchTeams,
  setCurrentTeam,
} from "../actions/teamsActions";
import EditTeamModal from "./EditTeamModal";
import TeamSubscriptionTab from "./TeamSubscriptionTab";
import TeamUsersTab from "./TeamUsersTab";
import TeamOverviewTab from "./TeamOverviewTab";
import TeamThemeTab from "./TeamThemeTab";
import TeamAvatar from "./TeamAvatar";

const { TabPane } = Tabs;

// Custom hook to get the previous value of a variable
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const TeamDetail = () => {
  const { t } = useTranslation("teams"); // Using the 'teams' namespace
  const { id } = useParams(); // Use the team ID from the URL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [deleting, setDeleting] = useState(false); // New state to track deletion

  const team = useSelector((state) =>
    state.teams.teams.find((team) => team.id === Number(id))
  );
  const currentTeam = useSelector((state) => state.teams.currentTeam);

  const isCurrentTeamSetByDetailPage = useRef(false);
  const prevCurrentTeam = usePrevious(currentTeam);

  useEffect(() => {
    if (deleting) return;

    const fetchTeamData = async () => {
      if (!team) {
        try {
          await dispatch(fetchTeam(id));
        } catch (error) {
          message.error(t("team-detail.error"));
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, [dispatch, id, team, deleting, t]);

  useEffect(() => {
    if (team && (!currentTeam || currentTeam.id !== team.id)) {
      dispatch(setCurrentTeam(team));
    }
  }, [team, currentTeam, dispatch]);

  useEffect(() => {
    if (
      prevCurrentTeam &&
      prevCurrentTeam.id === Number(id) &&
      currentTeam &&
      currentTeam.id !== Number(id)
    ) {
      // currentTeam changed from the current team to a different team
      navigate(`/teams/${currentTeam.id}/`);
    } else if (
      prevCurrentTeam &&
      prevCurrentTeam.id === Number(id) &&
      !currentTeam
    ) {
      // currentTeam was set to null (e.g., team deleted), navigate appropriately
      navigate("/teams/");
    }
  }, [currentTeam, prevCurrentTeam, id, navigate]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          padding: "20px",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!team && !deleting) {
    return <div>{t("team-detail.error")}</div>;
  }

  const handleEditTeam = () => {
    setIsEditModalVisible(true);
  };

  const handleDeleteTeam = () => {
    Modal.confirm({
      title: t("team-detail.delete.title"),
      content: t("team-detail.delete.content"),
      okText: t("team-detail.delete.ok_text"),
      okType: "danger",
      cancelText: t("team-detail.delete.cancel_text"),
      onOk: async () => {
        setDeleting(true); // Set deleting to true to prevent further fetching
        setLoading(true); // Set loading state to true to show a loading spinner

        try {
          await dispatch(removeTeam(team.id));
          await dispatch(fetchTeams()); // Refresh the list of teams after deletion

          message.success(t("team-detail.delete.success"));

          // Set current team to null
          dispatch(setCurrentTeam(null));

          // Navigate after deletion and state updates are complete
          navigate("/teams/");
        } catch (error) {
          message.error(
            t("team-detail.delete.error", {
              error: error.response?.data?.detail || error.message,
            })
          );
        } finally {
          setDeleting(false); // Reset deleting state after deletion is complete
          setLoading(false); // Ensure loading is stopped
        }
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="edit" icon={<EditOutlined />} onClick={handleEditTeam}>
        <span>{t("team-detail.link.edit")}</span>
      </Menu.Item>
      <Menu.Item
        key="delete"
        danger
        icon={<DeleteOutlined />}
        onClick={handleDeleteTeam}
      >
        <span>{t("team-detail.link.delete")}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={8}>
        <Card>
          <div style={{ position: "relative" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                icon={<MoreOutlined />}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: 999,
                }}
              />
            </Dropdown>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <TeamAvatar
                image={team.image}
                name={team.name}
                primaryColor={team.primary_color}
                size={128}
              />
              <Typography.Title level={2}>{team.name}</Typography.Title>
            </div>
            <p>
              <Typography.Text strong>
                {t("team-detail.info.identifier")}:
              </Typography.Text>{" "}
              {team.id}
            </p>
            <p>
              <Typography.Text strong>
                {t("team-detail.info.owner")}:
              </Typography.Text>{" "}
              {`${team.owner.user.first_name} ${team.owner.user.last_name}`}
            </p>
            <p>
              <Typography.Text strong>
                {t("team-detail.info.created")}:
              </Typography.Text>{" "}
              {new Date(team.created).toLocaleDateString()}
            </p>
            <p>
              <Typography.Text strong>
                {t("team-detail.info.last_updated")}:
              </Typography.Text>{" "}
              {new Date(team.modified).toLocaleDateString()}
            </p>
          </div>
        </Card>
      </Col>
      <Col xs={24} md={16}>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("team-detail.tabs.overview")} key="1">
            <TeamOverviewTab team={team} />
          </TabPane>
          <TabPane tab={t("team-detail.tabs.subscription")} key="2">
            <TeamSubscriptionTab team={team} />
          </TabPane>
          <TabPane tab={t("team-detail.tabs.users")} key="3">
            <TeamUsersTab team={team} />
          </TabPane>
          <TabPane tab={t("team-detail.tabs.theme")} key="4">
            <TeamThemeTab team={team} />
          </TabPane>
        </Tabs>
      </Col>
      <EditTeamModal
        isVisible={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
        team={team}
      />
    </Row>
  );
};

export default TeamDetail;
