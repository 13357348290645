// Filename: components/PageChangelogFilter.js

import React, { useEffect, useState, useCallback } from "react";
import { Form, Input, Select, DatePicker, Switch, message } from "antd";
import { useTranslation } from "react-i18next";
import {
  fetchChangeTypes,
  fetchProjectUsers,
} from "../../changelogs/api/changelogsApi";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";

const { RangePicker } = DatePicker;

const PageChangelogFilter = ({ onFilterChange }) => {
  const { t } = useTranslation("pages");
  const currentProject = useSelector((state) => state.projects.currentProject);

  const [changeTypes, setChangeTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [loadingChangeTypes, setLoadingChangeTypes] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [form] = Form.useForm();

  // Fetch change types and users when the project changes
  useEffect(() => {
    if (currentProject?.id) {
      setLoadingChangeTypes(true);
      fetchChangeTypes(currentProject.id)
        .then((data) => setChangeTypes(Array.isArray(data) ? data : []))
        .catch((error) => {
          console.error("Error fetching change types:", error);
          message.error(
            t("page-detail.changelog.filter.error.fetch_change_types")
          );
          setChangeTypes([]);
        })
        .finally(() => setLoadingChangeTypes(false));

      setLoadingUsers(true);
      fetchProjectUsers(currentProject.id)
        .then((data) => setUsers(Array.isArray(data) ? data : []))
        .catch((error) => {
          console.error("Error fetching project users:", error);
          message.error(
            t("page-detail.changelog.filter.error.fetch_project_users")
          );
          setUsers([]);
        })
        .finally(() => setLoadingUsers(false));
    } else {
      setChangeTypes([]);
      setUsers([]);
      form.resetFields();
    }
  }, [currentProject, t, form]);

  // Debounced filter change handler
  const debouncedFilterChange = useCallback(
    debounce((values) => {
      const { date_range, exclude_initial, ...rest } = values;
      const newFilters = { ...rest };

      if (date_range && date_range.length === 2) {
        newFilters.created_from = date_range[0].format("YYYY-MM-DD");
        newFilters.created_to = date_range[1].format("YYYY-MM-DD");
      }

      if (exclude_initial) {
        newFilters.is_initial = false;
      } else {
        delete newFilters.is_initial; // Include all logs if unchecked
      }

      onFilterChange(newFilters);
    }, 500),
    [onFilterChange]
  );

  const handleValuesChange = (_, allValues) => {
    debouncedFilterChange(allValues);
  };

  return (
    <Form
      form={form}
      layout="inline"
      onValuesChange={handleValuesChange}
      initialValues={{
        ordering: "-created_at",
        exclude_initial: false,
      }}
    >
      <Form.Item name="search">
        <Input.Search
          placeholder={t("page-detail.changelog.filter.placeholder.search")}
          allowClear
          style={{ width: 200 }}
        />
      </Form.Item>
      <Form.Item name="changetype">
        <Select
          placeholder={t("page-detail.changelog.filter.filter.changetype")}
          style={{ width: 200 }}
          allowClear
          loading={loadingChangeTypes}
          disabled={loadingChangeTypes || changeTypes.length === 0}
        >
          {changeTypes.map((ct) => (
            <Select.Option key={ct.id} value={ct.id}>
              {ct.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="date_range">
        <RangePicker disabled={loadingChangeTypes || loadingUsers} />
      </Form.Item>
      <Form.Item name="user">
        <Select
          placeholder={t("page-detail.changelog.filter.filter.user")}
          style={{ width: 200 }}
          allowClear
          loading={loadingUsers}
          disabled={loadingUsers || users.length === 0}
        >
          {users.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.first_name} {user.last_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="ordering">
        <Select
          placeholder={t("page-detail.changelog.filter.filter.ordering")}
          style={{ width: 150 }}
          allowClear
          disabled={loadingChangeTypes || loadingUsers}
        >
          <Select.Option value="-created_at">
            {t("page-detail.changelog.filter.ordering_options.newest")}
          </Select.Option>
          <Select.Option value="created_at">
            {t("page-detail.changelog.filter.ordering_options.oldest")}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="exclude_initial" valuePropName="checked">
        <Switch
          checkedChildren={t(
            "page-detail.changelog.filter.filter.exclude_initial.on"
          )}
          unCheckedChildren={t(
            "page-detail.changelog.filter.filter.exclude_initial.off"
          )}
        />
      </Form.Item>
    </Form>
  );
};

export default PageChangelogFilter;
