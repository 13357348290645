// Filename: api/scrapeApi.js
import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Start website scrape for a project
export const startScrape = async (projectId, options = {}) => {
  const response = await apiClient.post("/scraper/scrape/", {
    projectId,
    ...options,
  });
  return response.data;
};

// Get scrape progress for a project
export const getScrapeProgress = async (projectId) => {
  const response = await apiClient.get(`/scraper/progress/${projectId}/`);
  return response.data;
};
