// components/PageKeywordFilter.js

import React, { useCallback } from "react";
import { Form, Input, Segmented } from "antd";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

const PageKeywordFilter = ({ onFilterChange }) => {
  const { t } = useTranslation("pages");
  const [form] = Form.useForm();

  // Debounced filter change handler to limit API calls
  const debouncedFilterChange = useCallback(
    debounce((newFilters) => {
      onFilterChange(newFilters);
    }, 500), // 500ms debounce delay
    [onFilterChange]
  );

  const handleValuesChange = (changedValues, allValues) => {
    const { search, stats_date_range } = allValues;
    const newFilters = {};

    if (search) {
      newFilters.search = search;
    }

    if (stats_date_range) {
      newFilters.stats_date_range = stats_date_range;
    } else {
      // Default to 'last_month' if not selected
      newFilters.stats_date_range = "last_month";
    }

    debouncedFilterChange(newFilters);
  };

  return (
    <Form
      form={form}
      layout="inline"
      onValuesChange={handleValuesChange}
      initialValues={{
        stats_date_range: "last_month", // Set default value
      }}
    >
      {/* Search Input */}
      <Form.Item name="search">
        <Input.Search
          placeholder={t("page-detail.keywords.filter.placeholder.search")}
          allowClear
          style={{ width: 200 }}
        />
      </Form.Item>

      {/* Stats Date Range Segmented Control */}
      <Form.Item
        name="stats_date_range"
        label={t("page-detail.keywords.stats.label")}
      >
        <Segmented
          options={[
            {
              label: t("page-detail.keywords.stats.last_week"),
              value: "last_week",
            },
            {
              label: t("page-detail.keywords.stats.last_month"),
              value: "last_month",
            },
            {
              label: t("page-detail.keywords.stats.last_year"),
              value: "last_year",
            },
          ]}
        />
      </Form.Item>
    </Form>
  );
};

export default PageKeywordFilter;
