import React, { useState, useEffect } from "react";
import { Dropdown, Avatar, Menu, Typography, message } from "antd";
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  FormatPainterOutlined,
  SunOutlined,
  MoonOutlined,
  BulbOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../hooks/useResponsive";
import { setTheme } from "../actions/layoutActions";
import { fetchTeams, setCurrentTeam } from "../../teams/actions/teamsActions";
import { logoutUser } from "../../accounts/actions/authActions";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { CircleFlag } from "react-circle-flags";
import TeamAvatar from "../../teams/components/TeamAvatar";

const AvatarDropdown = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { t } = useTranslation("layout");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const themeMode = useSelector((state) => state.layout.themeMode);
  const themeUserToggled = useSelector((state) => state.layout.userToggled);

  const teams = useSelector((state) => state.teams.teams);
  const currentTeam = useSelector((state) => state.teams.currentTeam);

  const { mdUp } = useResponsive();

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  useEffect(() => {
    if (currentTeam && !currentTeam.name) {
      const savedTeam = teams.find((team) => team.id === currentTeam.id);
      if (savedTeam) {
        dispatch(setCurrentTeam(savedTeam));
      }
    } else if (!currentTeam && teams.length > 0) {
      dispatch(setCurrentTeam(teams[0]));
    }
  }, [currentTeam, teams, dispatch]);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/account/login");
  };

  const handleThemeChange = (themeOption) => {
    dispatch(
      setTheme({
        themeMode: themeOption,
      })
    );
    message.success(
      t("private.success.switch_theme", {
        themeMode: t(`private.label.${themeOption}_mode`),
      })
    );
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("i18nextLng", language);
    setSelectedLanguage(language);
    message.success(
      t("private.success.switch_language", {
        language: t(`private.label.${language}_language`),
      })
    );
  };

  const handleTeamChange = (teamId) => {
    const selectedTeamData = teams.find((team) => team.id === teamId);
    if (selectedTeamData) {
      dispatch(setCurrentTeam(selectedTeamData));
      message.success(
        t("private.success.switch_team", { teamName: selectedTeamData.name })
      );
    }
  };

  const selectedTheme = themeUserToggled ? themeMode : "system";

  const userMenuItems = [
    {
      key: "profile",
      icon: <UserOutlined />,
      label: t("private.link.profile"),
      onClick: () => navigate("/account/profile"),
    },
    {
      key: "theme",
      icon: <FormatPainterOutlined />,
      label: t("private.link.change_theme"),
      children: [
        {
          key: "light",
          icon: <SunOutlined />,
          label: t("private.link.light_theme"),
          onClick: () => handleThemeChange("light"),
        },
        {
          key: "dark",
          icon: <MoonOutlined />,
          label: t("private.link.dark_theme"),
          onClick: () => handleThemeChange("dark"),
        },
        {
          key: "system",
          icon: <BulbOutlined />,
          label: t("private.link.system_theme"),
          onClick: () => handleThemeChange("system"),
        },
      ],
    },
    {
      key: "language",
      icon: <BulbOutlined />,
      label: t("private.link.change_language"),
      children: [
        {
          key: "en",
          label: (
            <div className="language-option">
              <CircleFlag countryCode="uk" height="24" /> English
            </div>
          ),
          onClick: () => handleLanguageChange("en"),
        },
        {
          key: "nl",
          label: (
            <div className="language-option">
              <CircleFlag countryCode="nl" height="24" /> Nederlands
            </div>
          ),
          onClick: () => handleLanguageChange("nl"),
        },
      ],
    },
    {
      key: "team", // Team selection submenu
      icon: <TeamOutlined />,
      label: t("private.link.change_team"),
      children: teams.map((team) => {
        return {
          key: String(team.id), // Team id as the key
          label: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <TeamAvatar
                image={team.image}
                name={team.name}
                primaryColor={team.primary_color}
                size={24}
              />
              <Typography.Text style={{ marginLeft: "8px" }}>
                {team.name}
              </Typography.Text>
            </div>
          ),
          onClick: () => handleTeamChange(team.id), // Change team on click
        };
      }),
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: t("private.link.logout"),
      onClick: handleLogout,
    },
  ];

  const userMenu = (
    <Menu
      items={userMenuItems}
      selectedKeys={[selectedLanguage, selectedTheme, String(currentTeam?.id)]} // Highlight current language, theme, and team
      selectable={false}
    />
  );

  return (
    <Dropdown overlay={userMenu} trigger={["click"]}>
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <Avatar
          style={{ backgroundColor: "var(--primary-color)" }}
          size="large"
        >
          {user ? user.email.charAt(0).toUpperCase() : "U"}
        </Avatar>
        <span style={{ marginLeft: 8 }}>
          {mdUp && (user ? user.email.trim() : "User")}
          <DownOutlined style={{ marginLeft: 8 }} />
        </span>
      </div>
    </Dropdown>
  );
};

export default AvatarDropdown;
