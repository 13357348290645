// api/keywordsApi.js

import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Fetch pages for a specific keyword
export const fetchKeywordPages = async (projectId, keywordId, params = {}) => {
  const response = await apiClient.get(`/pages/`, {
    params: {
      include: "stats",
      projectid: projectId,
      keyword_id: keywordId,
      ...params,
    },
  });
  return response.data;
};
