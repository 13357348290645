// Filename: components/ChangelogsList.js

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Spin, Typography, message, Empty, Divider, Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { getChangelogs } from "../actions/changelogsActions";
import ChangeLogFilter from "./ChangeLogFilter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ChangeTypeModal from "./ChangeTypeModal";

const ChangelogsList = () => {
  const dispatch = useDispatch();
  const { changelogs, loading, error } = useSelector(
    (state) => state.changelogs
  );
  const currentProject = useSelector((state) => state.projects.currentProject);
  const { t } = useTranslation("changelogs"); // Gebruik specifieke namespace voor consistentie

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filters, setFilters] = useState({});
  const [ordering, setOrdering] = useState("-created_at");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1); // Reset naar eerste pagina bij filterwijziging
  };

  useEffect(() => {
    if (currentProject?.id) {
      const params = {
        page,
        page_size: pageSize,
        ordering,
        ...filters,
      };
      dispatch(getChangelogs(currentProject.id, params));
    }
  }, [currentProject, dispatch, page, pageSize, filters, ordering]);

  useEffect(() => {
    if (error) {
      message.error(t("changelogs-list.error.loading_failed"));
    }
  }, [error, t]);

  // Definieer de kolommen voor de tabel
  const columns = [
    {
      title: t("changelogs-list.label.created_at"),
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      render: (text) => new Date(text).toLocaleString(),
      width: 180,
    },
    {
      title: t("changelogs-list.label.changetype"),
      dataIndex: ["changetype", "name"],
      key: "changetype",
      sorter: true,
      width: 150,
    },
    {
      title: t("changelogs-list.label.index"),
      dataIndex: "index",
      key: "index",
      width: 200,
    },
    {
      title: t("changelogs-list.label.page"),
      dataIndex: ["page", "url"],
      key: "page",
      sorter: true,
      render: (text, record) => (
        <>
          <Link to={`/pages/${record.page.id}/`}>{text}</Link>{" "}
          <a href={text} target="_blank" rel="noopener noreferrer">
            <LinkOutlined />
          </a>
        </>
      ),
      width: 200,
    },
    {
      title: t("changelogs-list.label.old_value"),
      dataIndex: "old_value",
      key: "old_value",
      render: (text) => text || t("changelogs-list.label.no_value"),
      width: 200,
    },
    {
      title: t("changelogs-list.label.new_value"),
      dataIndex: "new_value",
      key: "new_value",
      render: (text) => text || t("changelogs-list.label.no_value"),
      width: 200,
    },
    {
      title: t("changelogs-list.label.user"),
      dataIndex: ["user", "email"],
      key: "user",
      sorter: true,
      render: (text, record) =>
        record.user
          ? `${record.user.first_name} ${record.user.last_name}`
          : t("changelogs-list.label.no_value"),
      width: 150,
    },
  ];

  // Handmatige paginatie instellingen via Ant Design Table
  const handleTableChange = (pagination, filtersFromTable, sorter) => {
    // Update pagina en paginagrootte
    setPage(pagination.current);
    setPageSize(pagination.pageSize);

    // Update ordering op basis van sorteer informatie
    if (sorter.order) {
      const orderPrefix = sorter.order === "ascend" ? "" : "-";
      setOrdering(`${orderPrefix}${sorter.field}`);
    } else {
      setOrdering("-created_at"); // Standaard ordering
    }
  };

  // Function to show the ChangeTypeModal
  const showChangeTypeModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Typography.Title level={2}>
        {t("changelogs-list.title")}
      </Typography.Title>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <ChangeLogFilter onFilterChange={handleFilterChange} />
        <Button type="primary" onClick={showChangeTypeModal}>
          {t("changelogs-list.button.manage_changetypes")}
        </Button>
      </div>
      <Divider />
      {/* Tabel met changelogs */}
      {changelogs.results && changelogs.results.length > 0 ? (
        <Table
          columns={columns}
          dataSource={changelogs.results}
          rowKey="id"
          loading={loading}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: changelogs.count,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t(
                "changelogs-list.pagination.showing"
              )} ${total} ${t("changelogs-list.pagination.items")}`,
          }}
          onChange={handleTableChange}
          locale={{
            emptyText: <Empty description={t("changelogs-list.no_changes")} />,
          }}
          bordered
        />
      ) : (
        <Empty description={t("changelogs-list.no_changes")} />
      )}
      <ChangeTypeModal visible={isModalVisible} onClose={handleModalClose} />
    </div>
  );
};

export default ChangelogsList;
