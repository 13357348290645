import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Fetch changelogs for a project
export const fetchChangelogs = async (projectId, params = {}) => {
  const response = await apiClient.get(`/projects/${projectId}/changelogs/`, {
    params,
  });
  return response.data;
};

// Fetch change types for a project (for filtering)
export const fetchChangeTypes = async (projectId) => {
  const response = await apiClient.get(`/projects/${projectId}/changetypes/`);
  return response.data;
};

// Fetch users associated with the project (for filtering)
export const fetchProjectUsers = async (projectId) => {
  const response = await apiClient.get(`/projects/${projectId}/users/`);
  return response.data;
};
