import React from "react";
import { message } from "antd";
import { initiateGoogleLogin } from "../api/authApi";
import googleLogo from "../../../../assets/google-logo.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const GoogleLoginButton = () => {
  const { t } = useTranslation("accounts");
  const themeMode = useSelector((state) => state.layout.themeMode); // Get the current theme mode

  const handleGoogleLogin = async () => {
    try {
      const authorization_url = await initiateGoogleLogin();

      if (authorization_url) {
        // Redirect the user to the authorization URL
        window.location.href = authorization_url;
      } else {
        message.error(t("google-login.error.specific.no_authorization_url"));
      }
    } catch (error) {
      message.error(t("google-login.error.generic"));
    }
  };

  return (
    <div
      onClick={handleGoogleLogin}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 20px",
        borderRadius: "5px",
        border: themeMode === "dark" ? "1px solid #444" : "1px solid #d9d9d9",
        cursor: "pointer",
        backgroundColor: themeMode === "dark" ? "#333" : "#fff",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.3s",
      }}
      onMouseEnter={(e) =>
        (e.currentTarget.style.backgroundColor =
          themeMode === "dark" ? "#444" : "#f5f5f5")
      }
      onMouseLeave={(e) =>
        (e.currentTarget.style.backgroundColor =
          themeMode === "dark" ? "#333" : "#fff")
      }
    >
      <img
        src={googleLogo}
        alt="Google Logo"
        style={{ height: "20px", marginRight: "10px" }}
      />
      <span
        style={{
          fontSize: "16px",
          color: themeMode === "dark" ? "#fff" : "#000",
        }}
      >
        {t("google-login.button")}
      </span>
    </div>
  );
};

export default GoogleLoginButton;
