import {
  fetchPages,
  fetchPageDetail,
  fetchPageChangeLogs,
} from "../api/pagesApi";

import { fetchPageKeywords } from "../api/keywordsApi";

// Fetch pages for a project with support for pagination, filtering, and search
export const getPages =
  (projectId, params = {}) =>
  async (dispatch) => {
    dispatch({ type: "PAGES_LOADING" });

    try {
      const data = await fetchPages(projectId, params);
      dispatch({
        type: "PAGES_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "PAGES_FAILURE",
        error: error.message,
      });
    }
  };

// Fetch details for a specific page
export const getPageDetail = (pageId) => async (dispatch) => {
  dispatch({ type: "PAGE_DETAIL_LOADING" });

  try {
    const data = await fetchPageDetail(pageId);
    dispatch({
      type: "PAGE_DETAIL_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "PAGE_DETAIL_FAILURE",
      error: error.message,
    });
  }
};

// Fetch changelogs for a specific page
export const getPageChangeLogs =
  (pageId, params = {}) =>
  async (dispatch) => {
    dispatch({ type: "PAGE_CHANGELOGS_LOADING" });

    try {
      const data = await fetchPageChangeLogs(pageId, params);
      dispatch({
        type: "PAGE_CHANGELOGS_SUCCESS",
        payload: data, // Ensure this contains count, next, previous, and results
      });
    } catch (error) {
      dispatch({
        type: "PAGE_CHANGELOGS_FAILURE",
        error: error.message,
      });
    }
  };

export const getPageKeywords =
  (projectId, pageId, params = {}) =>
  async (dispatch) => {
    dispatch({ type: "PAGE_KEYWORDS_LOADING" });

    try {
      const data = await fetchPageKeywords(projectId, pageId, params);
      dispatch({
        type: "PAGE_KEYWORDS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "PAGE_KEYWORDS_FAILURE",
        error: error.message,
      });
    }
  };
