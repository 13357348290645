// components/TeamSelectDropdown.js

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Button, Empty, Typography, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { fetchTeams, setCurrentTeam } from "../actions/teamsActions";
import TeamAvatar from "./TeamAvatar";
import CreateTeamModal from "./CreateTeamModal";

const { Option } = Select;

const TeamDropdown = ({ collapsed }) => {
  const { t } = useTranslation("teams");
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.teams.teams);
  const currentTeam = useSelector((state) => state.teams.currentTeam);
  const [isCreateTeamModalVisible, setIsCreateTeamModalVisible] =
    useState(false);

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  useEffect(() => {
    if (currentTeam && !currentTeam.name) {
      const savedTeam = teams.find((team) => team.id === currentTeam.id);
      if (savedTeam) {
        dispatch(setCurrentTeam(savedTeam));
      }
    } else if (!currentTeam && teams.length > 0) {
      dispatch(setCurrentTeam(teams[0]));
    }
  }, [currentTeam, teams, dispatch]);

  const handleTeamChange = (teamId) => {
    const selectedTeamData = teams.find((team) => team.id === teamId);
    if (selectedTeamData) {
      dispatch(setCurrentTeam(selectedTeamData));
      message.success(
        t("team-dropdown.success", { teamName: selectedTeamData.name })
      );
    }
  };

  return (
    <>
      <Select
        style={{
          width: "100%",
          height: "48px",
          maxWidth: "100%",
          position: "relative",
          zIndex: 1000,
        }}
        value={currentTeam?.name ? currentTeam?.id : null}
        onChange={handleTeamChange}
        popupMatchSelectWidth={false}
        getPopupContainer={(trigger) => trigger.parentNode}
        optionLabelProp="label"
        labelInValue={false}
        placeholder={t("team-dropdown.placeholder")}
      >
        {teams.length === 0 ? (
          <Option disabled key="no-teams">
            <Empty
              description={<span>{t("team-dropdown.no-projects")}</span>}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsCreateTeamModalVisible(true)} // Open the create team modal
              >
                {t("team-dropdown.button.create")}
              </Button>
            </Empty>
          </Option>
        ) : (
          teams.map((team) => (
            <Option
              key={team.id}
              value={team.id}
              label={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <TeamAvatar
                    image={team.image}
                    name={team.name}
                    primaryColor={team.primary_color}
                    size={24}
                  />
                  {!collapsed && (
                    <Typography.Text strong style={{ marginLeft: "8px" }}>
                      {team.name}
                    </Typography.Text>
                  )}
                </div>
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TeamAvatar
                  image={team.image}
                  name={team.name}
                  primaryColor={team.primary_color}
                  size={32}
                />
                <Typography.Text style={{ marginLeft: "8px" }}>
                  {team.name}
                </Typography.Text>
              </div>
            </Option>
          ))
        )}
      </Select>
      <CreateTeamModal
        isVisible={isCreateTeamModalVisible}
        onClose={() => setIsCreateTeamModalVisible(false)}
      />
    </>
  );
};

export default TeamDropdown;
