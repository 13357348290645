import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

const Dashboard = () => {
  const { t } = useTranslation("dashboard");
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.projects.currentProject);

  return (
    <div>
      <Typography.Title level={2}>
        {t("title")} {currentProject?.name}
      </Typography.Title>
      <p>{t("text")}</p>
    </div>
  );
};

export default Dashboard;
