import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Tabs, Badge, message } from "antd";
import { useTranslation } from "react-i18next";
import {
  createNewProject,
  updateExistingProject,
  getProjects,
} from "../../actions/projectsActions";
import { fetchTeams } from "../../../../core/teams/actions/teamsActions";
import GeneralTab from "./GeneralTab";
import KeywordsTab from "./KeywordsTab";
import ScrapingTab from "./ScrapingTab";
import IntegrationsTab from "./IntegrationsTab";
import UsersTab from "./UsersTab";
import { formatKeywords } from "./helpers";

const { TabPane } = Tabs;

function AddEditProjectModal({
  isOpen,
  onClose,
  project = null,
  initialActiveTab = 1,
}) {
  const { t } = useTranslation("projects");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentTeam = useSelector((state) => state.teams.currentTeam);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [generalValidationErrors, setGeneralValidationErrors] = useState(0);
  const [keywordValidationErrors, setKeywordValidationErrors] = useState(0);
  const [scrapingEnabled, setScrapingEnabled] = useState(false); // Updated scrapingEnabled state
  const [users, setUsers] = useState([]);

  const defaultInformationalKeywords = [
    "what",
    "who",
    "when",
    "where",
    "which",
    "why",
    "how",
  ];
  const defaultTransactionalKeywords = [
    "buy",
    "order",
    "purchase",
    "price",
    "cheap",
  ];
  const defaultCommercialKeywords = [
    "top",
    "best",
    "review",
    "comparison",
    "compare",
    "versus",
    "vs",
  ];

  useEffect(() => {
    console.log("initialActiveTab", initialActiveTab);
    setActiveTabKey(activeTabKey);
  }, [initialActiveTab]);

  useEffect(() => {
    console.log("activeTabKey", activeTabKey);
  }, [activeTabKey]);

  const updateValidationErrors = () => {
    const generalErrors = form
      .getFieldsError(["name", "url"])
      .filter((field) => field.errors.length > 0).length;

    const keywordErrors = form
      .getFieldsError([
        "keywords_brand",
        "keywords_intent_informational",
        "keywords_intent_transactional",
        "keywords_intent_commercial",
      ])
      .filter((field) => field.errors.length > 0).length;

    setGeneralValidationErrors(generalErrors);
    setKeywordValidationErrors(keywordErrors);
  };

  const handleFieldChange = (changedFields) => {
    if (generalValidationErrors + keywordValidationErrors > 0) {
      updateValidationErrors();
    }
  };

  // Effect to set form values and control scrapingEnabled state
  useEffect(() => {
    dispatch(fetchTeams());
    if (project) {
      // If editing a project, populate form fields with project data
      form.setFieldsValue({
        name: project.name,
        url: project.url,
        sitemap_url: project.settings?.sitemap_url || "",
        scraping_enabled: project.settings?.scraping_enabled || false,
        scraping_mode: project.settings?.scraping_mode || "sitemap",
        scraping_render_js: project.settings?.scraping_render_js || false,
        keywords_brand:
          project.settings?.keywords_brand?.split(", ").join("\n") || "",
        keywords_intent_informational:
          project.settings?.keywords_intent_informational
            ?.split(", ")
            .join("\n") || "",
        keywords_intent_transactional:
          project.settings?.keywords_intent_transactional
            ?.split(", ")
            .join("\n") || "",
        keywords_intent_commercial:
          project.settings?.keywords_intent_commercial
            ?.split(", ")
            .join("\n") || "",
      });
      setScrapingEnabled(project.settings?.scraping_enabled || false);
    } else {
      // If adding a new project, set default values
      form.setFieldsValue({
        name: "",
        url: "",
        sitemap_url: "",
        scraping_enabled: false,
        scraping_mode: "sitemap",
        scraping_render_js: false,
        keywords_brand: "",
        keywords_intent_informational: defaultInformationalKeywords.join("\n"),
        keywords_intent_transactional: defaultTransactionalKeywords.join("\n"),
        keywords_intent_commercial: defaultCommercialKeywords.join("\n"),
      });
      setScrapingEnabled(false);
    }

    if (currentTeam && currentTeam.users) {
      const teamUsers = currentTeam.users.map((teamUser) => {
        const projectUser = project?.users?.find(
          (pu) => pu.user.id === teamUser.user.id
        );
        return {
          ...teamUser.user, // Flatten user data
          permission: projectUser ? projectUser.permission : "none",
        };
      });
      setUsers(teamUsers);
    }

    updateValidationErrors();

    setActiveTabKey(initialActiveTab);
  }, [dispatch, project, form, isOpen]);

  const handlePermissionChange = (userId, permission) => {
    const updatedUsers = users.map((user) => {
      if (user.id === userId) {
        return { ...user, permission };
      }
      return user;
    });
    setUsers(updatedUsers);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      await form.validateFields();
      updateValidationErrors();

      const projectData = {
        name: values.name,
        url: values.url,
        settings: {
          sitemap_url: values.sitemap_url || "",
          scraping_enabled: values.scraping_enabled || false,
          scraping_mode: values.scraping_mode || null,
          scraping_render_js: values.scraping_render_js || false,
          keywords_brand: formatKeywords(values.keywords_brand),
          keywords_intent_informational: formatKeywords(
            values.keywords_intent_informational
          ),
          keywords_intent_transactional: formatKeywords(
            values.keywords_intent_transactional
          ),
          keywords_intent_commercial: formatKeywords(
            values.keywords_intent_commercial
          ),
        },
        organization: currentTeam?.id,
        users: users
          .filter((user) => user.permission && user.permission !== "none")
          .map((user) => ({
            user_id: user.id,
            permission: user.permission,
          })),
      };

      if (project) {
        // Update an existing project
        await dispatch(updateExistingProject(project.id, projectData));
        message.success(t("projects-tabs.success.edit"));
      } else {
        // Create a new project
        await dispatch(createNewProject(projectData));
        message.success(t("projects-tabs.success.add"));
      }

      form.resetFields();
      dispatch(getProjects(currentTeam.id));
      onClose(); // Close the modal after submission
      if (initialActiveTab === "4") {
        // Assuming tab "4" is Integrations
        navigate("/projects");
      }
    } catch {
      if (project) {
        message.error(t("projects-tabs.error.edit"));
      } else {
        message.error(t("projects-tabs.error.add"));
      }
    } finally {
      setLoading(false); // Stop loading spinner after submission
    }
  };

  return (
    <Modal
      title={
        project ? t("projects-tabs.title.edit") : t("projects-tabs.title.add")
      }
      open={isOpen}
      onOk={() => form.submit()} // Submit the form when OK is clicked
      onCancel={() => {
        form.resetFields(); // Reset form fields when modal is canceled
        onClose();
      }}
      okButtonProps={{ loading }}
      okText={
        project ? t("projects-tabs.button.edit") : t("projects-tabs.button.add")
      }
      cancelText="Cancel"
      width={1000}
    >
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={handleFieldChange}
        onFinish={handleFormSubmit}
        onFinishFailed={updateValidationErrors}
      >
        <Tabs
          tabPosition="left"
          activeKey={activeTabKey}
          onChange={(key) => setActiveTabKey(key)}
          defaultActiveKey="1"
        >
          <TabPane
            tab={
              <>
                {t("projects-tabs.tabs.general")}
                {generalValidationErrors > 0 && (
                  <Badge
                    count={generalValidationErrors}
                    style={{ marginLeft: 8 }}
                  />
                )}
              </>
            }
            key="1"
            forceRender={true}
          >
            <GeneralTab form={form} url={project?.url || ""} />
          </TabPane>
          <TabPane
            tab={
              <>
                {t("projects-tabs.tabs.keywords")}
                {keywordValidationErrors > 0 && (
                  <Badge
                    count={keywordValidationErrors}
                    style={{ marginLeft: 8 }}
                  />
                )}
              </>
            }
            key="2"
            forceRender={true}
          >
            <KeywordsTab form={form} />
          </TabPane>
          <TabPane
            tab={t("projects-tabs.tabs.scraping")}
            key="3"
            forceRender={true}
          >
            <ScrapingTab
              scrapingEnabled={scrapingEnabled}
              setScrapingEnabled={setScrapingEnabled}
              form={form}
            />
          </TabPane>
          <TabPane
            tab={t("projects-tabs.tabs.integrations")}
            key="4"
            forceRender={true}
          >
            <IntegrationsTab project={project} />
          </TabPane>
          <TabPane
            tab={t("projects-tabs.tabs.users")}
            key="5"
            forceRender={true}
          >
            <UsersTab
              users={users}
              onPermissionChange={handlePermissionChange}
            />
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
}

export default AddEditProjectModal;
