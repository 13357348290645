// components/experimentForms/SingleExperimentForm.js

import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  DatePicker,
  message,
  Divider,
  Switch,
  Select,
  Table,
  Radio,
  Row,
  Col,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createNewExperiment } from "../../actions/experimentsActions";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;

const SingleExperimentForm = ({ visible, onCancel, onSuccess }) => {
  const { t } = useTranslation("experiments");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.projects.currentProject);
  const [showAdjustChangePeriod, setShowAdjustChangePeriod] = useState(false);
  const [showRelatedChangelogs, setShowRelatedChangelogs] = useState(false);
  const [experimentPeriod, setExperimentPeriod] = useState(14); // Default to 14 days
  const [experimentPeriodDays, setExperimentPeriodDays] = useState(14); // New variable to track experiment days
  const [calculatedDates, setCalculatedDates] = useState({});

  const handleCreateExperiment = (values) => {
    const experimentData = {
      ...values,
      experiment_type: "single",
      control_start_date: values.control_period[0].format("YYYY-MM-DD"),
      control_end_date: values.control_period[1].format("YYYY-MM-DD"),
      test_start_date: values.test_period[0].format("YYYY-MM-DD"),
      test_end_date: values.test_period[1].format("YYYY-MM-DD"),
      project: currentProject.id,
    };

    dispatch(createNewExperiment(experimentData))
      .then(() => {
        message.success(t("experiments-list.success.experiment_created"));
        form.resetFields();
        onSuccess();
      })
      .catch((err) => {
        message.error(t("experiments-list.error.creation_failed"));
        console.error(err);
      });
  };

  const handleExperimentPeriodChange = (e) => {
    const value = e.target.value;
    setExperimentPeriod(value);
    if (value !== "custom") {
      setExperimentPeriodDays(value);
    }
  };

  const handleCustomPeriodChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setExperimentPeriodDays(value);
  };

  const calculatePeriods = () => {
    const changePeriod = form.getFieldValue("change_period");
    if (changePeriod && changePeriod.length === 2) {
      const startChange = changePeriod[0];
      const endChange = changePeriod[1];
      const days = experimentPeriodDays;

      const controlStart = moment(startChange).subtract(days, "days");
      const controlEnd = startChange;
      const testStart = endChange;
      const testEnd = moment(endChange).add(days, "days");

      form.setFieldsValue({
        control_period: [controlStart, controlEnd],
        test_period: [testStart, testEnd],
      });

      setCalculatedDates({
        controlStartDate: controlStart.format("YYYY-MM-DD"),
        controlEndDate: controlEnd.format("YYYY-MM-DD"),
        testStartDate: testStart.format("YYYY-MM-DD"),
        testEndDate: testEnd.format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    calculatePeriods();
  }, [experimentPeriodDays, form.getFieldValue("change_period")]);

  return (
    <Modal
      title={t("single-experiment.modal.title")}
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleCreateExperiment(values);
          })
          .catch((info) => {
            console.log("Validation Failed:", info);
          });
      }}
      width={1000}
    >
      <Form form={form} layout="vertical">
        <Divider>
          {t("single-experiment.section.experiment_name_and_goals")}
        </Divider>
        <Form.Item
          name="experiment_name"
          label={t("single-experiment.label.experiment_name")}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={t("single-experiment.placeholder.experiment_name")}
          />
        </Form.Item>
        <Form.Item
          name="hypothesis"
          label={t("single-experiment.label.hypothesis")}
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="target_keywords"
          label={t("single-experiment.label.target_keywords")}
        >
          <Select
            mode="tags"
            placeholder={t("single-experiment.placeholder.target_keywords")}
          ></Select>
        </Form.Item>

        <Divider>{t("single-experiment.section.experiment_settings")}</Divider>
        <Form.Item
          name="page_url"
          label={t("single-experiment.label.page_url")}
          rules={[{ required: true, type: "url" }]}
        >
          <Input placeholder={t("single-experiment.placeholder.page_url")} />
        </Form.Item>
        <Form.Item
          name="change_period"
          label={t("single-experiment.label.change_period")}
          rules={[{ required: true }]}
        >
          <RangePicker onChange={calculatePeriods} />
        </Form.Item>
        <Form.Item
          name="use_related_changelogs"
          label={t("single-experiment.label.use_related_changelogs")}
          valuePropName="checked"
        >
          <Switch
            onChange={(checked) => {
              setShowAdjustChangePeriod(checked);
              setShowRelatedChangelogs(checked);
            }}
          />
        </Form.Item>
        {showAdjustChangePeriod && (
          <Form.Item
            name="adjust_change_period_by_changelogs"
            label={t(
              "single-experiment.label.adjust_change_period_by_changelogs"
            )}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        )}

        <Divider>{t("single-experiment.section.experiment_period")}</Divider>
        <Radio.Group
          onChange={handleExperimentPeriodChange}
          value={experimentPeriod}
          style={{ width: "100%" }}
        >
          <Radio.Button value={7} style={{ width: "25%" }}>
            {t("single-experiment.label.experiment_period.7_days")}
          </Radio.Button>
          <Radio.Button value={14} style={{ width: "25%" }}>
            {t("single-experiment.label.experiment_period.14_days")}
          </Radio.Button>
          <Radio.Button value={30} style={{ width: "25%" }}>
            {t("single-experiment.label.experiment_period.30_days")}
          </Radio.Button>
          <Radio.Button value="custom" style={{ width: "25%" }}>
            {experimentPeriod === "custom" ? (
              <Input
                style={{
                  width: "100%",
                  marginLeft: 10,
                  border: 0,
                  background: "transparent",
                }}
                type="number"
                min={1}
                value={experimentPeriodDays}
                onChange={handleCustomPeriodChange}
                suffix={t("single-experiment.label.experiment_period.days")}
              />
            ) : (
              t("single-experiment.label.experiment_period.custom")
            )}
          </Radio.Button>
        </Radio.Group>

        {calculatedDates.controlStartDate && (
          <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
            <Col span={12} style={{ textAlign: "center" }}>
              <Typography.Text strong>
                {t("single-experiment.label.control_period")}:
              </Typography.Text>{" "}
              <Typography.Text>
                {t("single-experiment.label.period", {
                  start_date: calculatedDates.controlStartDate,
                  end_date: calculatedDates.controlEndDate,
                })}
              </Typography.Text>
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <Typography.Text strong>
                {t("single-experiment.label.test_period")}:
              </Typography.Text>{" "}
              <Typography.Text>
                {t("single-experiment.label.period", {
                  start_date: calculatedDates.testStartDate,
                  end_date: calculatedDates.testEndDate,
                })}
              </Typography.Text>
            </Col>
          </Row>
        )}

        {showRelatedChangelogs && (
          <>
            <Divider>
              {t("single-experiment.section.related_changelogs")}
            </Divider>
            <Table
              columns={[
                {
                  title: t("single-experiment.label.changelogs.date"),
                  dataIndex: "date",
                  key: "date",
                },
                {
                  title: t("single-experiment.label.changelogs.type"),
                  dataIndex: "type",
                  key: "type",
                },
                {
                  title: t("single-experiment.label.changelogs.action"),
                  dataIndex: "action",
                  key: "action",
                },
              ]}
              dataSource={[]}
              pagination={false}
            />
          </>
        )}
        {/* Add more fields specific to the single experiment */}
      </Form>
    </Modal>
  );
};

export default SingleExperimentForm;
