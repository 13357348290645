import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Fetch keywords for a specific page
export const fetchPageKeywords = async (projectId, pageId, params = {}) => {
  const response = await apiClient.get(`/keywords/`, {
    params: {
      projectid: projectId,
      page_id: pageId,
      include: "stats",
      ...params,
    },
  });
  return response.data;
};
