// Filename: actions/keywordsActions.js

import { fetchKeywords, fetchKeywordDetail } from "../api/keywordsApi";
import { fetchKeywordPages } from "../api/pagesApi";

// Fetch keywords for a project with support for pagination, filtering, and search
export const getKeywords =
  (projectId, params = {}) =>
  async (dispatch) => {
    dispatch({ type: "KEYWORDS_LOADING" });

    try {
      const data = await fetchKeywords(projectId, params);
      dispatch({
        type: "KEYWORDS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "KEYWORDS_FAILURE",
        error: error.message,
      });
    }
  };

// Fetch details for a specific keyword
export const getKeywordDetail = (keywordId) => async (dispatch) => {
  dispatch({ type: "KEYWORD_DETAIL_LOADING" });

  try {
    const data = await fetchKeywordDetail(keywordId);
    dispatch({
      type: "KEYWORD_DETAIL_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "KEYWORD_DETAIL_FAILURE",
      error: error.message,
    });
  }
};

// Fetch pages associated with a specific keyword
export const getKeywordPages =
  (projectId, keywordId, params = {}) =>
  async (dispatch) => {
    dispatch({ type: "KEYWORD_PAGES_LOADING" });

    try {
      const data = await fetchKeywordPages(projectId, keywordId, params);
      dispatch({
        type: "KEYWORD_PAGES_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "KEYWORD_PAGES_FAILURE",
        error: error.message,
      });
    }
  };
