import React from "react";
import { Avatar } from "antd";

const ProjectAvatar = ({ image, name, primaryColor, size = 128 }) => {
  return (
    <Avatar
      size={size}
      src={`data:image/png;base64,${image}` || null}
      style={{
        backgroundColor: !image ? "var(--primary-color)" : "transparent",
        border: "0",
      }}
    >
      {!image && name.charAt(0).toUpperCase()}{" "}
    </Avatar>
  );
};

export default ProjectAvatar;
