// src/components/ChangeTypeModal.js

import React, { useEffect, useState } from "react";
import {
  Tabs,
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Popconfirm,
  Empty,
} from "antd";
import {
  getChangeTypes,
  addChangeTypeAction,
  editChangeTypeAction,
  removeChangeTypeAction,
} from "../actions/changetypesActions"; // Ensure correct path
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const ChangeTypeModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const { changeTypes, loading, error } = useSelector(
    (state) => state.changetypes
  );
  const currentProject = useSelector((state) => state.projects.currentProject);
  const { t } = useTranslation("changelogs");

  const [isEditing, setIsEditing] = useState(false);
  const [editingChangeType, setEditingChangeType] = useState(null);
  const [form] = Form.useForm();

  // State to track the active tab
  const [activeTab, setActiveTab] = useState("custom");

  useEffect(() => {
    if (currentProject?.id && visible) {
      dispatch(getChangeTypes(currentProject.id));
    }
  }, [dispatch, currentProject, visible]);

  const showEditModal = (changeType) => {
    setIsEditing(true);
    setEditingChangeType(changeType);
    form.setFieldsValue({
      name: changeType.name,
      selector: changeType.selector,
    });
  };

  const showCreateModal = () => {
    setIsEditing(false);
    setEditingChangeType(null);
    form.resetFields();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (isEditing && editingChangeType) {
          dispatch(
            editChangeTypeAction(
              currentProject.id,
              editingChangeType.id,
              values
            )
          )
            .then(() => {
              message.success(t("changetype-modal.success.update"));
              form.resetFields();
            })
            .catch(() => {
              message.error(t("changetype-modal.error.update"));
            });
        } else {
          dispatch(addChangeTypeAction(currentProject.id, values))
            .then(() => {
              message.success(t("changetype-modal.success.create"));
              form.resetFields();
            })
            .catch(() => {
              message.error(t("changetype-modal.error.create"));
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    onClose();
    form.resetFields();
    setIsEditing(false);
    setEditingChangeType(null);
  };

  const handleDelete = (id) => {
    dispatch(removeChangeTypeAction(currentProject.id, id))
      .then(() => {
        message.success(t("changetype-modal.success.delete"));
      })
      .catch(() => {
        message.error(t("changetype-modal.error.delete"));
      });
  };

  // Separate standard and custom ChangeTypes
  const standardChangeTypes = changeTypes.filter((ct) => ct.project === null);
  const customChangeTypes = changeTypes.filter(
    (ct) => ct.project === currentProject.id
  );

  // Define table columns for ChangeTypes
  const columns = [
    {
      title: t("changetype-modal.label.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("changetype-modal.label.selector"),
      dataIndex: "selector",
      key: "selector",
    },
    {
      title: t("changetype-modal.label.actions"),
      key: "actions",
      render: (_, record) => {
        // Determine if the change type is standard
        const isStandard = record.project === null;

        // If it's a standard ChangeType, do not show Edit/Delete buttons
        if (isStandard) {
          return null; // Optionally, return <span>Read-Only</span> or similar
        }

        return (
          <>
            <Button type="link" onClick={() => showEditModal(record)}>
              {t("changetype-modal.button.edit")}
            </Button>
            <Popconfirm
              title={t("changetype-modal.popconfirm.title")}
              onConfirm={() => handleDelete(record.id)}
              okText={t("changetype-modal.popconfirm.ok")}
              cancelText={t("changetype-modal.popconfirm.cancel")}
            >
              <Button type="link" danger>
                {t("changetype-modal.button.delete")}
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  // Handle tab change
  const handleTabChange = (key) => {
    setActiveTab(key);
    // Reset form and editing state when switching tabs
    form.resetFields();
  };

  return (
    <Modal
      title={t("changetype-modal.title")}
      visible={visible}
      onOk={activeTab === "custom" ? handleOk : null} // Disable "Ok" button for standard tab
      onCancel={handleCancel}
      okText={
        isEditing
          ? t("changetype-modal.button.update")
          : t("changetype-modal.button.create")
      }
      cancelText={t("changetype-modal.button.cancel")}
      width={800}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          {t("changetype-modal.button.cancel")}
        </Button>,
        activeTab === "custom" && (
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            disabled={loading}
          >
            {isEditing
              ? t("changetype-modal.button.update")
              : t("changetype-modal.button.create")}
          </Button>
        ),
      ]}
    >
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        style={{ marginBottom: 16 }}
      >
        <TabPane tab={t("changetype-modal.tabs.standard")} key="standard">
          <Table
            columns={columns}
            dataSource={standardChangeTypes}
            rowKey="id"
            loading={loading}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  description={t("changetype-modal.no_standard_changetypes")}
                />
              ),
            }}
            bordered
          />
        </TabPane>
        <TabPane tab={t("changetype-modal.tabs.custom")} key="custom">
          <Button
            type="primary"
            onClick={showCreateModal}
            style={{ marginBottom: 16 }}
            disabled={loading}
          >
            {t("changetype-modal.button.add")}
          </Button>
          <Table
            columns={columns}
            dataSource={customChangeTypes}
            rowKey="id"
            loading={loading}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  description={t("changetype-modal.no_custom_changetypes")}
                />
              ),
            }}
            bordered
          />
        </TabPane>
      </Tabs>
      {/* Conditionally render the form only in the Custom tab */}
      {activeTab === "custom" && (
        <Form form={form} layout="vertical" style={{ marginTop: 16 }}>
          <Form.Item
            name="name"
            label={t("changetype-modal.label.name")}
            rules={[
              {
                required: true,
                message: t("changetype-modal.validator.name_required"),
              },
            ]}
          >
            <Input placeholder={t("changetype-modal.placeholder.name")} />
          </Form.Item>
          <Form.Item
            name="selector"
            label={t("changetype-modal.label.selector")}
            rules={[
              {
                required: true,
                message: t("changetype-modal.validator.selector_required"),
              },
            ]}
          >
            <Input placeholder={t("changetype-modal.placeholder.selector")} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default ChangeTypeModal;
