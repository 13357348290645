// Filename: components/ChangeLogFilter.js

import React, { useEffect, useState, useCallback } from "react";
import { Form, Input, Select, DatePicker, Switch, message } from "antd";
import { useTranslation } from "react-i18next";
import { fetchChangeTypes, fetchProjectUsers } from "../api/changelogsApi";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";

const { RangePicker } = DatePicker;

const ChangeLogFilter = ({ onFilterChange }) => {
  const { t } = useTranslation("changelogs");
  const currentProject = useSelector((state) => state.projects.currentProject);

  const [changeTypes, setChangeTypes] = useState([]);
  const [users, setUsers] = useState([]);

  const [loadingChangeTypes, setLoadingChangeTypes] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [form] = Form.useForm();

  // Debounced filter change handler to limit API calls
  const debouncedFilterChange = useCallback(
    debounce((changedValues, allValues) => {
      const { date_range, exclude_initial, ...rest } = allValues;
      const newFilters = { ...rest };

      if (date_range && date_range.length === 2) {
        newFilters.date_from = date_range[0].format("YYYY-MM-DD");
        newFilters.date_to = date_range[1].format("YYYY-MM-DD");
      }

      if (exclude_initial) {
        newFilters.is_initial = false;
      } else {
        delete newFilters.is_initial; // Remove filter to include all
      }

      onFilterChange(newFilters);
    }, 500), // 500ms debounce delay
    [onFilterChange]
  );

  useEffect(() => {
    if (currentProject?.id) {
      // Fetch Change Types
      setLoadingChangeTypes(true);
      fetchChangeTypes(currentProject.id)
        .then((data) => {
          if (Array.isArray(data)) {
            setChangeTypes(data);
          } else {
            console.error("Unexpected data format for change types:", data);
            setChangeTypes([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching change types:", error);
          message.error(t("changelogs-filter.error.fetch_change_types"));
          setChangeTypes([]); // Set to empty array on error
        })
        .finally(() => {
          setLoadingChangeTypes(false);
        });

      // Fetch Project Users
      setLoadingUsers(true);
      fetchProjectUsers(currentProject.id)
        .then((data) => {
          if (Array.isArray(data)) {
            setUsers(data);
          } else {
            console.error("Unexpected data format for users:", data);
            setUsers([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching project users:", error);
          message.error(t("changelogs-filter.error.fetch_project_users"));
          setUsers([]); // Set to empty array on error
        })
        .finally(() => {
          setLoadingUsers(false);
        });
    } else {
      // Reset filters if no project is selected
      setChangeTypes([]);
      setUsers([]);
      form.resetFields();
    }
  }, [currentProject, t, form]);

  const handleValuesChange = (changedValues, allValues) => {
    debouncedFilterChange(changedValues, allValues);
  };

  return (
    <Form
      form={form}
      layout="inline"
      onValuesChange={handleValuesChange}
      initialValues={{
        exclude_initial: false, // Default: show all logs
      }}
    >
      <Form.Item name="search">
        <Input.Search
          placeholder={t("changelogs-filter.placeholder.search")}
          allowClear
          style={{ width: 200 }}
          loading={loadingChangeTypes || loadingUsers}
        />
      </Form.Item>
      <Form.Item name="changetype">
        <Select
          placeholder={t("changelogs-filter.filter.changetype")}
          style={{ width: 200 }}
          allowClear
          loading={loadingChangeTypes}
          disabled={loadingChangeTypes || changeTypes.length === 0}
        >
          {changeTypes.map((ct) => (
            <Select.Option key={ct.id} value={ct.id}>
              {ct.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="date_range">
        <RangePicker disabled={loadingChangeTypes || loadingUsers} />
      </Form.Item>
      <Form.Item name="user">
        <Select
          placeholder={t("changelogs-filter.filter.user")}
          style={{ width: 200 }}
          allowClear
          loading={loadingUsers}
          disabled={loadingUsers || users.length === 0}
        >
          {users.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.first_name} {user.last_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="exclude_initial" valuePropName="checked">
        <Switch
          checkedChildren={t("changelogs-filter.filter.exclude_initial.on")}
          unCheckedChildren={t("changelogs-filter.filter.exclude_initial.off")}
          style={{ marginLeft: 8 }}
        />
      </Form.Item>
    </Form>
  );
};

export default ChangeLogFilter;
