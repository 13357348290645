// Filename: api/pagesApi.js

import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Fetch pages for a project with optional filters, search, and ordering
export const fetchPages = async (projectId, params = {}) => {
  const response = await apiClient.get(`/pages/`, {
    params: { projectid: projectId, ...params },
  });
  return response.data;
};

// Fetch detail for a specific page including its changelogs
export const fetchPageDetail = async (pageId) => {
  const response = await apiClient.get(`/pages/${pageId}/`);
  return response.data;
};

// Fetch users associated with the project (for filtering)
export const fetchProjectUsers = async (projectId) => {
  const response = await apiClient.get(`/projects/${projectId}/users/`);
  return response.data;
};

// Fetch changelogs for a specific page
export const fetchPageChangeLogs = async (pageId, params = {}) => {
  const response = await apiClient.get(`/pages/${pageId}/changelogs/`, {
    params,
  });
  return response.data;
};
