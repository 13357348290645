import { getApiClient } from "../../../core/accounts/api/apiUtils";

const apiClient = getApiClient();

// Fetch change types for a project (for filtering)
export const fetchChangeTypes = async (projectId) => {
  const response = await apiClient.get(`/projects/${projectId}/changetypes/`);
  return response.data;
};

// Create a new ChangeType
export const createChangeType = async (projectId, data) => {
  const response = await apiClient.post(
    `/projects/${projectId}/changetypes/`,
    data
  );
  return response.data;
};

// Update an existing ChangeType
export const updateChangeType = async (projectId, id, data) => {
  const response = await apiClient.put(
    `/projects/${projectId}/changetypes/${id}/`,
    data
  );
  return response.data;
};

// Delete a ChangeType
export const deleteChangeType = async (projectId, id) => {
  const response = await apiClient.delete(
    `/projects/${projectId}/changetypes/${id}/`
  );
  return response.status;
};
