// components/experimentForms/SingleExperimentForm.js

import React from "react";
import { Modal, Form, Input, DatePicker, message } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createNewExperiment } from "../../actions/experimentsActions";

const { RangePicker } = DatePicker;

const SwitchExperimentForm = ({ visible, onCancel, onSuccess }) => {
  const { t } = useTranslation("experiments");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.projects.currentProject);

  const handleCreateExperiment = (values) => {
    const experimentData = {
      ...values,
      experiment_type: "single",
      control_start_date: values.control_date_range
        ? values.control_date_range[0].format("YYYY-MM-DD")
        : null,
      control_end_date: values.control_date_range
        ? values.control_date_range[1].format("YYYY-MM-DD")
        : null,
      test_start_date: values.test_date_range
        ? values.test_date_range[0].format("YYYY-MM-DD")
        : null,
      test_end_date: values.test_date_range
        ? values.test_date_range[1].format("YYYY-MM-DD")
        : null,
      project: currentProject.id,
    };
    delete experimentData.control_date_range;
    delete experimentData.test_date_range;

    dispatch(createNewExperiment(experimentData))
      .then(() => {
        message.success(t("experiments-list.success.experiment_created"));
        form.resetFields();
        onSuccess();
      })
      .catch((err) => {
        message.error(t("experiments-list.error.creation_failed"));
        console.error(err);
      });
  };

  return (
    <Modal
      title={t("switch-experiment.modal.title")}
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleCreateExperiment(values);
          })
          .catch((info) => {
            console.log("Validation Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="experiment_name"
          label={t("switch-experiment.form.experiment_name")}
          rules={[{ required: true }]}
        >
          <Input placeholder={t("switch-experiment.form.experiment_name")} />
        </Form.Item>
        <Form.Item
          name="control_date_range"
          label={t("switch-experiment.form.control_date_range")}
          rules={[{ required: true }]}
        >
          <RangePicker />
        </Form.Item>
        <Form.Item
          name="test_date_range"
          label={t("switch-experiment.form.test_date_range")}
          rules={[{ required: true }]}
        >
          <RangePicker />
        </Form.Item>
        <Form.Item
          name="hypothesis"
          label={t("switch-experiment.form.hypothesis")}
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        {/* Add more fields specific to the single experiment */}
      </Form>
    </Modal>
  );
};

export default SwitchExperimentForm;
