import React, { useState, useCallback, useEffect } from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

const GeneralTab = ({ form, url }) => {
  const { t } = useTranslation("projects");
  const [faviconUrl, setFaviconUrl] = useState(null);

  const validateUrl = (_, value) => {
    const pattern = /^(https?:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;
    if (!pattern.test(value)) {
      return Promise.reject(t("projects-general.validation.url_invalid"));
    }

    try {
      new URL(value);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(t("projects-general.validation.url_required"));
    }
  };

  const fetchFavicon = async (url) => {
    if (url) {
      const urlObject = new URL(url);
      const protocol = urlObject.protocol;
      const domain = urlObject.hostname;
      const fullDomain = `${protocol}//${domain}`;
      const faviconUrl = `https://www.google.com/s2/favicons?domain=${fullDomain}&size=128`;
      setFaviconUrl(faviconUrl);
    } else {
      setFaviconUrl(null);
      return false;
    }
  };

  const debouncedFetchFavicon = useCallback(
    debounce((url) => fetchFavicon(url), 500),
    []
  );

  useEffect(() => {
    if (url) {
      debouncedFetchFavicon(url);
    }
  }, [url, debouncedFetchFavicon]);

  const handleUrlBlur = async (event) => {
    try {
      const url = event.target.value;
      await form.validateFields(["url"]);
      debouncedFetchFavicon(url);
    } catch (error) {
      setFaviconUrl(null);
    }
  };

  useEffect(() => {
    if (!url) {
      setFaviconUrl(null);
    }
  }, [url]);

  return (
    <>
      <Form.Item
        label={t("projects-general.label.name")}
        name="name"
        rules={[
          {
            required: true,
            message: t("projects-general.validation.name_required"),
          },
        ]}
      >
        <Input placeholder={t("projects-general.placeholder.name")} />
      </Form.Item>

      <Form.Item
        label={t("projects-general.label.url")}
        name="url"
        rules={[{ required: true, validator: validateUrl }]}
      >
        <Input
          placeholder={t("projects-general.placeholder.url")}
          prefix={
            faviconUrl ? (
              <img
                src={faviconUrl}
                alt={t("projects-general.label.favicon")}
                style={{ width: 16, height: 16 }}
              />
            ) : null
          }
          onBlur={handleUrlBlur}
        />
      </Form.Item>
    </>
  );
};

export default GeneralTab;
